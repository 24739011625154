import React, { Component } from 'react';
import { graphql, withApollo } from 'react-apollo';
import compose from 'lodash.flowright';
import gql from 'graphql-tag';
import WithOrgData from '../../../WithOrgData';
import LoadingContent from '../../../LoadingContent';
import {Alert, Row, Col, Card, message, Switch, Tooltip} from 'antd';
import Person from './Person';
import Employer from './Employer';
import { withRouter } from 'react-router-dom';
import Location from './Location';
import {withUserGroups} from "../../../userGroup";
import {QueryGetWhiteList} from "../../../../Queries/WhiteList";

const SET_WHITE_LIST = gql`
mutation SetWhiteList(
    $_id: ID
    $customerID: ID!
    $tpID: ID!
    $organizationIds: [ID]
){
    setWhiteList(
        _id: $_id
        customerID: $customerID
        tpID: $tpID
        organizationIds: $organizationIds
    ){
        _id
        organizationIds {
            _id
            name
            classType
        }
    }
}
`

class WhiteList extends Component {
    onCustomerSwitchChange = (checked) => {
        const { customer, transporter } = this.props.orgData;

        const employers = this.props.data.getWhiteList.organizationIds.filter(
            org => org.classType === 'flytsuite.employer'
        );
        var organizations = [...employers];
        if (checked) {
            organizations.push({
                ...customer,
                classType: 'flytsuite.customer'
            })
        }
        const organizationIds = organizations.map(org => org._id)
        this.props.setWhiteList({
            variables: {
                _id: customer._id.replace('CUS-', 'WHL-'),
                tpID: transporter._id,
                customerID: customer._id,
                organizationIds
            },
            optimisticResponse: {
                __typename: 'Mutation',
                setWhiteList: {
                    __typename: 'WhiteList',
                    _id: this.props.data.getWhiteList._id,
                    organizationIds: organizations
                }
            }
        })
        .then(() => message.success("White list updated successfully"))
        .catch((error) => {
            console.error(error)
            message.error("Failed to update white list")
        })
    }
    renderContent = () => {
        const { getWhiteList, loading, error } = this.props.data;
        const { useDefault, userGroups } = this.props;

        const hasWhitelistAllGroup = userGroups.findIndex((group) => group === "flytsuite.whitelist.whitelistall") > -1;

        if (loading) return <LoadingContent />
        if (error) return <Alert type="error" message="Failed to load White List" description={error.message} showIcon />
        const currentTab = this.props.match.params.tab;
        const renderTab = () => {
            switch (currentTab) {
                case 'person':
                    return (
                        <div style={{margin: '-24px -32px'}}>
                            <Person fetchPolicy="cache-only" useDefault={useDefault} />
                        </div>
                    )
                case 'employer':
                return (
                    <div style={{margin: '-24px -32px'}}>
                        <Employer fetchPolicy="cache-first" useDefault={useDefault} />
                    </div>
                )
                case 'location':
                default:
                    return (
                        <div style={{margin: '-24px -32px'}}>
                            <Location fetchPolicy="cache-first" useDefault={useDefault} />
                        </div>
                    )
            }
        }
        const customerWhitelisted = getWhiteList.organizationIds.find(
            org => org._id === this.props.orgData.customer._id
        )

        const wlAllSwitch = (
            <Switch
                onChange={this.onCustomerSwitchChange} checked={!!customerWhitelisted}
                disabled={!hasWhitelistAllGroup}
            />
        )

        return (
            <div>
                <div style={{ margin: '1rem', marginLeft: 0}} >
                    <Row type="flex" gutter={24} >
                        <Col>
                            <span style={{ fontSize: '1.0rem' }} >White list all {this.props.orgData.customer.name} passengers</span>
                        </Col>
                        <Col>
                            {!hasWhitelistAllGroup ? (
                                <Tooltip
                                    title="You do not have permission to whitelist all passengers."
                                    disabled={hasWhitelistAllGroup}

                                >
                                    {wlAllSwitch}
                                </Tooltip>
                            ) : wlAllSwitch }

                        </Col>
                    </Row>
                </div>
                { !customerWhitelisted ? (
                    <Card 
                        tabList={[
                        {
                            tab: 'Location',
                            key: 'location'
                        },
                        {
                            tab: 'Employer',
                            key: 'employer'
                        },
                        {
                            tab: 'Personnel',
                            key: 'person'
                        },
                    ]}
                    activeTabKey={currentTab}
                    onTabChange={(key) => this.props.history.push(`/app/masterdata/whitelist/${key}`)}
                    >
                        {renderTab()}
                    </Card>
                ) : null }
            </div>
        )
    }
    render() { 
        return (
            <React.Fragment>
                <Row>
                    <Col span={12}>
                        <h2>ISN White List</h2>
                    </Col>
                </Row>
                {this.renderContent()}
            </React.Fragment>
        )
    }
}
 
export default compose(
    WithOrgData,
    withApollo,
    withUserGroups,
    graphql(
        QueryGetWhiteList,
        {
            options: props => ({
                variables: {
                    _id: props.orgData.customer._id.replace('CUS-', 'WHL-')
                },
                pollInterval: 15000,
                fetchPolicy: 'network-only'
            }),
            props: props => {
                const defaultWl = {
                    _id: props.ownProps.orgData.customer._id.replace('CUS-', 'WHL-'),
                    organizationIds: [],
                    personIds: [],
                    locationToEmployer: [],
                    __typename: 'WhiteList'
                }
                var error = props.data.error;
                if (error){
                    const notFoundError = error.graphQLErrors.find(err => err.errorType === 'not_found' && err.path.length === 1)
                    if (notFoundError){
                        return {
                            ...props,
                            useDefault: true,
                            data: {
                                ...props.data,
                                error: undefined,
                                loading: false,
                                getWhiteList: defaultWl
                            }
                        }
                    }
                }
                return {
                    ...props,
                    data: {
                        ...props.data,
                        getWhiteList: {
                            ...defaultWl,
                            ...(props.data.getWhiteList || {})
                        }
                    }
                }
            }
        }
    ),
    graphql(
        SET_WHITE_LIST,
        {
            name: 'setWhiteList'
        }
    ),
    withRouter
)(WhiteList);