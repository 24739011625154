import moment from 'moment'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    highlightFiltered: true,
    transporter: null,
    employer: null,
    organization: null,
    contracts: [],
    appLoaded: false,
    paginationLimit: 200,
    schedulingCalendarView: '0',
    schedulingDateRange: [moment().format('YYYY-MM-DD'), moment().add(1, 'month').format('YYYY-MM-DD')],
    reportDateRange: [moment().subtract(1, 'week').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')],
    navSiderCollapsed: false,
    globalFilter: {
        __typename: 'GlobalFilter',
        visible: false,
        customer: null,
        transporter: null,
        departure: null,
        destination: null,
        employer: null,
        pax: {
            __typename: 'PaxGlobalFilter',
            lastName: null,
            firstName: null
        },
        cgo: {
            __typename: 'CgoGlobalFilter',
            name: null
        }
    },
    userFilter: {
        paxSets: {
            owner: 'own',
            __typename: 'ScheduleFilter'
        },
        scheduledPax: {
            owner: 'own',
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().add(1, 'week').format('YYYY-MM-DD'),
            __typename: 'ScheduleFilter'
        },
        scheduledFlights: {
            owner: 'own',
            startDate: moment().format('YYYY-MM-DD'),
            endDate: moment().add(1, 'week').format('YYYY-MM-DD'),
            __typename: 'ScheduleFilter'
        },
        __typename: 'UserFilter'
    },
    tableSettings: [
        {
            name: 'livePassengers',
            __typename: 'TableSettingsItem',
            settings: {
                __typename: 'TableSettings',
                visibleColumns: [
                    'assignedFlight',
                    'name',
                    'employer',
                    'customer',
                    'transporter',
                    'weight',
                    'checkedInTime',
                    'bagWeight',
                    'bagCount',
                    'departure',
                    'destination',
                    'tsa',
                    'restrictions',
                    'isnStatus'
                ]
            }
        },
        {
            name: 'liveFlights',
            __typename: 'TableSettingsItem',
            settings: {
                __typename: 'TableSettings',
                visibleColumns: [
                    'desig',
                    'departureWeight',
                    'passengers',
                    'departure',
                    'destinations',
                    'seatPercent',
                    'weightPercent',
                    'status'
                ]
            }
        },
        {
            name: 'liveCargo',
            __typename: 'TableSettingsItem',
            settings: {
                __typename: 'TableSettings',
                visibleColumns: [
                    'assignedFlight',
                    'name',
                    'customer',
                    'checkedIn',
                    'departure',
                    'destination',
                    'deliveredBy',
                    'attentionTo',
                    'weight',
                    'hazmat'
                ]
            }
        }
    ]
}