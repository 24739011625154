import React from 'react';
import { Layout, Breadcrumb } from 'antd';
import { basePath } from '../../flights/scheduling/main';
import { Link, withRouter } from 'react-router-dom';
import { withUserGroups } from '../../userGroup';
import './SchedulingStepsHeader.less';

const { Header } = Layout;

const breadcrumbItems = [
    {
        text: 'Passenger Sets',
        to: '/app/scheduling/sets',
        userGroup: ['flytsuite.schedule.sets']
    },
    {
        text: 'Scheduled Passengers and Cargo',
        to: '/app/scheduling/groups',
        userGroup: ['flytsuite.schedule.passenger', 'flytsuite.schedule.passenger.view']
    },
    {
        text: 'Scheduled Flights',
        to: '/app/scheduling/flight',
        userGroup: ['flytsuite.schedule.flight']
    }
]

class SchedulingStepsHeader extends React.Component{
    renderBreadcrumbs = () => (
        breadcrumbItems.map((item, index) => {
            var style = {}
            let pathname = this.props.location.pathname;
            if (pathname.startsWith('/app/scheduling/schedule')){
                // Keep highlight when on V2 scheduling page
                pathname = pathname.replace('/app/scheduling/schedule', '/app/scheduling/groups')
            }
            if (pathname.startsWith(item.to)){
                style = {
                    color: '#1890ff'
                }
            }
            if (item.userGroup && item.userGroup.findIndex(g => this.props.userGroups.includes(g)) > -1){
                return (
                    <Breadcrumb.Item key={index}>
                        <Link style={style} to={item.to}>{item.text}</Link>
                    </Breadcrumb.Item>
                )
            }
            return null
        })
    )
    render(){
        return (
        <Header className="scheduling-steps-header">
            <Breadcrumb style={{fontSize: '1rem'}} separator=">">
                <Breadcrumb.Item>
                    <Link to={basePath()}>{/*<Icon type="home" style={{fontSize: '1rem'}} />*/}Start</Link>
                </Breadcrumb.Item>
                {this.renderBreadcrumbs()}
            </Breadcrumb>
        </Header>
        )
    }
}

export default withRouter( withUserGroups( SchedulingStepsHeader ) )