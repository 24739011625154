import {FlightLeg} from "../../schema";

export enum LiveEditedState {
    NONE = 'NONE',
    IS_EDITING = 'IS_EDITING',
    BLESSED = 'BLESSED'
}

export enum TransportStateEnum {
    NONE = 'NONE',
    ACTIVE = 'ACTIVE',
    OVERNIGHT = 'OVERNIGHT',
    LOCKED = 'LOCKED',
    DEPARTED = 'DEPARTED',
    RETIRED = 'RETIRED',
    FINALIZED = 'FINALIZED',
    SCHEDULED = 'SCHEDULED',
    STOLEN = 'STOLEN',
}

export interface Transport {
    _id: string,
    _rev: string,
    timeOfDeparture?: string,
    state?: TransportStateEnum,
    contract?: string,
    paxIDList?: any[],
    cgoIDList?: any[],
    legs?: {[key: string]: FlightLeg},
    firstLegUsableWt?: number,
    lastKnownController?: any,
    stolenByController?: any,
    liveEditedState?: LiveEditedState,
}