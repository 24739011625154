import { Stream } from 'stream';
import moment, { Moment } from 'moment-timezone';

// Based on https://github.com/aws/aws-sdk-js-v3/issues/1877#issuecomment-967223047
// To help with AWS's stupid change that makes reading S3 objects overly complicated.
export async function readableStreamToBuffer(stream: ReadableStream): Promise<Buffer> {
    const chunks: Buffer[] = [];
  
    const reader = stream.getReader();
  
    let moreData = true;
    do {
      // eslint-disable-next-line no-await-in-loop
      const { done, value } = await reader.read();
      if (done) {
        moreData = false;
      } else {
        chunks.push(Buffer.from(value as Uint8Array));
      }
    } while (moreData);
  
    return Buffer.concat(chunks);
  }

export interface TzZone {
    name: string,
    shorthand: string,
    offset: number
}

let timezones = {
  "America/Chicago": ["CST", -6, "CDT", -5],
  "America/Denver": ["MST", -7, "MDT", -6],
  "America/Los_Angeles": ["PST", -8, "PDT", -7],
  "America/Phoenix": ["AZST", -7, "AZDT", -7],
  "America/New_York": ["EST", -5, "EDT", -4],
  "Pacific/Honolulu": ["HST", -10, "HDT", -10]
}

let sAbbrToName = {}

Object.entries(timezones)
  .map(([ k, v ]) => sAbbrToName[v[0]] = k)

export function getZoneList(date: string, noDST=false): Array<TzZone> {

    let zoneNames = Object.entries<Array<string | number>>(timezones);
    let zoneList = zoneNames.map(([ name, [ abbrS, s, abbrD, d ] ]) => {
        let zone = moment.tz(date, name);
        if (zone.isDST() && !noDST){
            return {
                name,
                shorthand: String(abbrD),
                offset: Number(d)
            }
        }
        return {
            name: name,
            shorthand: String(abbrS),
            offset: Number(s)
        }
    })
    .sort((a, b) => {
            if (a.offset > b.offset){
                return -1
            }
            else
            {
                return 1
            }
        })
    return zoneList
}

export function getTzDataFromName(tzList: Array<TzZone>, tzName: string): TzZone {
  let tzEntry = tzList.find((entry) => entry.name === tzName);
  return tzEntry;
}

export function getTzNameFromDoc(doc: any){
  let defaultTz = "America/Chicago";
  const createdBy = doc.createdBy;
  if (!createdBy) return defaultTz;

  const cbArray = String(createdBy).split(".");
  const abbr = cbArray.find(el => String(el).toUpperCase() in sAbbrToName)?.toUpperCase();

  if (!abbr || !sAbbrToName[abbr]){
    return defaultTz;
  }

  return sAbbrToName[abbr];
}

/**
 * Appends or inserts an element into a mapping where the value is an array
 * @param map Map object to append to
 * @param key Key of the key/value pair to append to
 * @param valueToInsert Value to append to the value array
 * @returns 0 if value is appened to an existing element in the map; 1 if a new key is created in the map
 */
export function appendOrInsertMapValue<K, V>(map: Map<K, Array<V>>, key: K, valueToInsert: V): number {
  if (map.has(key)){
    map.get(key).push(valueToInsert);
    return 0
  }
  else
  {
    map.set(key, [valueToInsert]);
    return 1
  }
}

export function getLocalizedMoment(dateTime: string, doc: any): Moment{
  if (!dateTime) return null;

  let tzName = doc ? getTzNameFromDoc(doc) : 'America/Chicago';
  let tzAwareDT = moment(dateTime).parseZone().tz(tzName, false);
  return tzAwareDT;
}

export function getSortFunction<T = any>(itemGetter: ((item: T) => any) | string, order: 'asc' | 'desc'='asc'): (a: T, b: T) => number{
  const getValue = (obj: T) => {
    if (typeof itemGetter === 'function'){
      return itemGetter(obj);
    }
    return obj[itemGetter];
  }
  return (a, b) => {
    const aVal = getValue(a);
    const bVal = getValue(b);

    if (aVal > bVal){
      return order === 'asc' ? 1 : -1;
    }
    else if (bVal > aVal){
      return order === 'asc' ? -1 : 1;
    }
    return 0;
  }
}

export function momentSorter(fieldName, a, b) {
  const aa = moment(a[fieldName]);
  const bb = moment(b[fieldName]);
  if (aa.isBefore(bb)) return -1
  if (aa.isAfter(bb)) return 1
  return 0
}

export function getAreaBlock(locDoc: any){
  if (!locDoc) return null;
  if (locDoc.type === "OFFSHORE" && locDoc.block && locDoc.field){
      return `${locDoc.block}${locDoc.field}`
  }
  return null;
}

// Adds props to a component that keeps click events from
// propagating.
export function stopPropagationProps(){
    return {
        onClick: (e) => {
            e.stopPropagation();
        }
    }
}