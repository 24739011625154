import { Avatar, Button, Col, Descriptions, message, Row, Tabs, Input } from 'antd';
import { CountryCodeSelect } from 'components/form/select/CountryCodeSelect';
import EmployerSelectWithData from 'components/form/select/EmployerSelect';
import CovidVaccineDates from 'components/PassengerEditor/CovidVaccineDates';
import { withUserGroups } from 'components/userGroup';
import QueryResultContext from 'context/query-result-context';
import gql from 'graphql-tag';
import compose from 'lodash.flowright';
import PropTypes from 'prop-types';
import React, { Component, useState } from 'react';
import { Mutation, withApollo } from 'react-apollo';
import WithOrgData from '../../components/WithOrgData';
import { CredentialManagerWithData } from '../CredentialManager';
import './PassengerEditor.less';


const SET_PERSON = gql`

    mutation SetPerson(
        $payload: PersonInput!,
        $orgID: ID!
    ){
        setPerson(
            payload: $payload
            orgID: $orgID
        ){
            _id
            dob
            nation
            notes
            employerID {
                _id
                name
            }
            contactInfo {
                phone {
                    number
                    type
                }
            }
        }
    }
`

class PassengerEditor extends Component {
    state = {
        visible: false,
        error: false
    }
    setPicError = (error) => {
        this.setState({ error: error })
    }
    setPictureVisible = (visible) => {
        this.setState({ visible: visible })
    }

    renderPicture = () => {
        const { data } = this.props;
        const personId = data.personID._id;
        const path = "https://s3.amazonaws.com/df-web-img/pax/" + personId + "/" + personId + "_prof.jpeg";
        if (this.state.error) {
            return (
                <Avatar shape="square" size={300} style={{ marginBottom: 20 }}>No Image Found</Avatar>
            )
        }
        else {
            return (
                this.state.visible ?
                    <Avatar shape="square" size={300} style={{ marginBottom: 20 }} src={path} onError={e => this.setPicError(true)} />
                    : <Avatar shape="square" size={300} style={{ marginBottom: 20 }} icon="user" />
            )
        }
    }



    renderNationSelect = () => {
        const { data, edit } = this.props;

        return (
            <Mutation
                mutation={SET_PERSON}
                onCompleted={() => message.success('Passenger nation successfuly updated')}
                onError={() => message.error('Failed to update passenger\'s nation. An error has occurred.')}
            >
                {(mutate) => {
                    return (
                        <CountryCodeSelect
                            disabled={!edit}
                            value={data.personID.nation}
                            onChange={(nation) => {
                                mutate({
                                    variables: {
                                        payload: {
                                            _id: this.props.data.personID._id,
                                            nation
                                        },
                                        orgID: this.props.orgData.getOrgByType('transporter')._id
                                    },
                                    optimisticResponse: {
                                        __typename: 'Mutation',
                                        setPerson: {
                                            __typename: 'Person',
                                            ...this.props.data.personID,
                                            nation
                                        }
                                    }
                                })
                            }}
                        >
                        </CountryCodeSelect>
                    )
                }}
            </Mutation>
        )
    }

    renderInfo = () => {
        const { data, edit } = this.props;
        return (
            <Tabs.TabPane key="personalInfo" tab="Personal Information">
                <Row type="flex" gutter={12} style={{ flexWrap: 'nowrap' }}>
                    <Col>
                        <Button style={{ marginBottom: '12px', display: 'block' }} onClick={() => this.setPictureVisible(true)} disabled={this.state.visible}> Click here to show image </Button>
                        {this.renderPicture()}
                    </Col>
                    <Col style={{ flex: 1 }}>
                        <Descriptions column={1} bordered size="small" title="Personal Info">
                            <Descriptions.Item label="Date of Birth"><span className="pepi-value">{data.personID.dob}</span></Descriptions.Item>
                            <Descriptions.Item label="Phone Number">
                                <span className="pepi-value">{data.personID?.contactInfo?.phone?.number ? (
                                    `${data.personID.contactInfo.phone.number} (${data.personID.contactInfo.phone.type})`
                                ) : null}</span>
                            </Descriptions.Item>
                            <Descriptions.Item label="Nation">{this.renderNationSelect()}</Descriptions.Item>
                            <Descriptions.Item label="Employer">
                                <QueryResultContext.Consumer>
                                    {(queryResult) => (
                                        <Mutation
                                            mutation={gql`
                                        mutation SetPersonPaxEmployer(
                                            $personPayload: PersonInput!,
                                            $paxPayload: PaxNodeInput!,
                                            $orgID: ID!
                                        ){
                                            setPerson(
                                                payload: $personPayload
                                                orgID: $orgID
                                            ){
                                                _id
                                                employerID {
                                                    _id
                                                    name
                                                }
                                            }
                                            setPaxNode(
                                                payload: $paxPayload
                                            ){
                                                _id
                                                employerID{
                                                    _id
                                                    name
                                                }
                                                personID {
                                                    _id
                                                    employerID{
                                                        _id
                                                        name
                                                    }
                                                }
                                            }
                                        }
                                        `}
                                            onCompleted={() => {
                                                message.success('Passenger employer successfuly updated')
                                                console.log('queryResult', queryResult);
                                                if (queryResult) {
                                                    queryResult.refetch();
                                                }
                                            }}
                                            onError={() => message.error('Failed to update passenger\'s employer. An error has occurred.')}
                                        >
                                            {(mutate) => {
                                                return (
                                                    <EmployerSelectWithData
                                                        mode="single"
                                                        disabled={!edit}
                                                        showSearch
                                                        labelInValue
                                                        style={{ width: 200 }}
                                                        value={{ key: data.employerID._id, label: data.employerID.name }}
                                                        onChange={(e) => {
                                                            let empObj = {
                                                                _id: e.key,
                                                                name: e.label,
                                                                __typename: 'Employer'
                                                            }
                                                            mutate({
                                                                variables: {
                                                                    personPayload: {
                                                                        _id: this.props.data.personID._id,
                                                                        employerID: e.key
                                                                    },
                                                                    paxPayload: {
                                                                        _id: this.props.data._id,
                                                                        employerID: e.key,
                                                                        tpID: this.props.orgData.getOrgByType('transporter')._id
                                                                    },
                                                                    orgID: this.props.orgData.getOrgByType('transporter')._id
                                                                },
                                                                update: (cache, { data: { setPaxNode } }) => {
                                                                    cache.writeFragment({
                                                                        fragment: gql`
                                                                    fragment Pax on PaxNode {
                                                                        _id
                                                                        employerID {
                                                                            _id
                                                                            name
                                                                        }
                                                                    }
                                                                `,
                                                                        id: 'PaxNode:' + setPaxNode._id,
                                                                        data: {
                                                                            ...setPaxNode,
                                                                            __typename: 'PaxNode',
                                                                            employerID: empObj
                                                                        }
                                                                    })
                                                                },
                                                                optimisticResponse: {
                                                                    __typename: 'Mutation',
                                                                    setPerson: {
                                                                        __typename: 'Person',
                                                                        ...this.props.data.personID,
                                                                        employerID: empObj
                                                                    },
                                                                    setPaxNode: {
                                                                        __typename: 'PaxNode',
                                                                        ...this.props.data,
                                                                        personID: {
                                                                            ...this.props.data.personID,
                                                                            employerID: empObj
                                                                        },
                                                                        employerID: empObj
                                                                    }
                                                                }
                                                            })
                                                        }}
                                                        placeholder="Select an Employer"
                                                    >
                                                    </EmployerSelectWithData>
                                                )
                                            }}
                                        </Mutation>
                                    )}
                                </QueryResultContext.Consumer>
                            </Descriptions.Item>
                        </Descriptions>
                    </Col>
                </Row>
            </Tabs.TabPane>
        )
    }

    renderCredentials = () => {
        const { data } = this.props;

        return (
            <Tabs.TabPane key="cred" tab="Credentials">
                <CredentialManagerWithData showCount personId={
                    data && data.personID && data.personID._id
                } paxID={data && data._id} />
            </Tabs.TabPane>
        )
    }


    render() {
        const { data, style, className, userGroups } = this.props;
        return (
            <div style={style} className={className}>
                {data && (
                    <h3>{`${data.lastName}, ${data.firstName}`}</h3>
                )}
                <Tabs type="card" defaultActiveKey="personalInfo">
                    {this.renderInfo()}
                    {this.renderCredentials()}
                    {userGroups.includes('flytsuite.covid19vaxrecords.read') ? (
                        <Tabs.TabPane key="health" tab="Health Info">
                            <CovidVaccineDates personID={data.personID._id} personName={`${data.personID.lastName}, ${data.personID.firstName}`} />
                        </Tabs.TabPane>
                    ) : null}
                    {this.renderPersonalNotes()}
                </Tabs>
            </div>
        );
    }

    handleNotesChange = (event) => {
        this.setState({ changedNotes: event.target.value });
    }

    resetNotes = () => {
        this.setState({ changedNotes: undefined });
    }


    renderPersonalNotes = () => {

        const { data, edit } = this.props;

        /*If there are NO NOTES , then PULL (CURRENT PERSON) NOTES FROM PERSON RECORD*/
        let textAreaValue = this.state.changedNotes || data.personID.notes;

        return (
            <Tabs.TabPane key="notesKey" tab="Notes">
                <Mutation
                    mutation={SET_PERSON}
                    onCompleted={() => {
                        message.success('Passenger notes successfuly updated');
                        this.setState({ changedNotes: undefined });
                    }}
                    onError={() => message.error('Failed to update passenger\'s notes. An error has occurred.')}
                >
                    {(mutate, { loading }) => {
                        return (
                            <>
                                <label>Notes : </label>

                                <Input.TextArea
                                    style={{
                                        resize: 'none'
                                    }}
                                    name="message"
                                    placeholder="Type notes here…"
                                    value={textAreaValue}
                                    onChange={this.handleNotesChange}
                                    rows={15}
                                    cols={50}
                                />

                                <Row style={{ marginTop: 6 }} gutter={6} type="flex">
                                    <Col>
                                        <Button
                                            disabled={!this.state.changedNotes}
                                            onClick={this.resetNotes}
                                        >Reset edits</Button>
                                    </Col>
                                    <Col>
                                        <Button
                                            type="primary"

                                            disabled={!this.state.changedNotes}
                                            loading={loading}

                                            onClick={() => {
                                                mutate({
                                                    variables: {
                                                        payload: {
                                                            _id: this.props.data.personID._id,
                                                            notes: this.state.changedNotes
                                                        },
                                                        orgID: this.props.orgData.getOrgByType('transporter')._id
                                                    },
                                                    optimisticResponse: {
                                                        __typename: 'Mutation',
                                                        setPerson: {
                                                            __typename: 'Person',
                                                            ...this.props.data.personID,
                                                            notes: this.state.changedNotes
                                                        }
                                                    }
                                                })
                                            }}>Save post
                                        </Button>
                                    </Col>
                                </Row>
                            </>
                        )
                    }}
                </Mutation>
            </Tabs.TabPane>
        )
    }
}





PassengerEditor.propTypes = {
    data: PropTypes.object.isRequired,
    onSave: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    editing: PropTypes.string,
    onRemove: PropTypes.func

}


export default compose(
    withApollo,
    WithOrgData,
    withUserGroups
)(PassengerEditor)