import {
    Alert,
    Button,
    Card,
    Col,
    DatePicker,
    Drawer,
    Dropdown,
    Form,
    Icon,
    Input,
    Layout,
    Menu,
    message,
    Modal,
    Popconfirm,
    Row,
    Spin,
    Tabs,
    Typography
} from 'antd';
import {getFlightLegManagerFromFlightQuery} from 'common/flight-leg/util';
import AddFuelStopToFlightDrawer from 'components/flights/scheduling/AddFuelStopToFlightDrawer';
import AddLegsToFlightDrawer from 'components/flights/scheduling/AddLegsToFlightDrawer';
import FlightManualScheduleLegPax from 'components/flights/scheduling/FlightManualScheduleLegPax';
import FlightManualScheduleLegs from 'components/flights/scheduling/FlightManualScheduleLegs';
import ContractSelect from 'components/form/ContractSelect';
import {LocationSelect} from 'components/form/select/LocationSelect';
import gql from 'graphql-tag';
import moment from 'moment';
import React, {useContext, useMemo, useRef, useState} from 'react';
import {Mutation, Query, useApolloClient, useQuery} from 'react-apollo';
import {useHistory, useParams, withRouter} from 'react-router-dom';
import {castArray, createNewLeg, getDestinationsFromLegs, paxOnFlightLeg, safeGet} from '../../../common/util';
import AircraftSelect from '../../form/AircraftSelect';
import LocationOnshoreSelect from '../../form/LocationOnshoreSelect';
import PilotSelect from '../../form/PilotSelect';
import LoadingContent from '../../LoadingContent';
import NotAuthorized from '../../NotAuthorized';
import MainHeader, {MainHeaderProps} from '../../scheduling/MainHeader';
import MainLayout from '../../scheduling/MainLayout';
import useManifestPrintout from '../../scheduling/Printout/useManifestPrintout';
import {UserGroup} from '../../userGroup';
import withCognitoUser from '../../WithCognitoUser';
import WithOrgData, {OrgDataProps, WithOrgDataProps} from '../../WithOrgData';
import './FlightScheduleEdit.less';
import FlightScheduleLegPax from './FlightScheduleLegPax';
import FlightScheduleLegs from './FlightScheduleLegs';
import {OrgDataContext} from "../../../context/orgData";
import {useMutation} from "@apollo/react-hooks";
import {FlightLeg} from "../../../schema";
import {useMultiSelect} from "../../../hooks/useMultiSelect";
import {ButtonProps} from "antd/lib/button";
import {FormComponentProps} from "antd/lib/form/Form";
import {createScheduledEntityColumns} from "./columns";
import ETable from "../../enchanced-antd-table";
import {renderGroupNameMenuItems} from "./FlightScheduleUtils";
import {ScheduledFlightFragment} from "../../../Queries/Scheduling";
import {LocationFragment} from "../../../Queries/Location";
import ScheduledPaxCgoAdder from "./scheduled-paxcgo-adder";
import pluralize from 'pluralize';
import useBreakpoints from "../../../hooks/useBreakpoints";
import {NotesForPilotEditor} from "./notes-for-pilot/editor/notes-for-pilot-editor";
import {useNotesForPilotEditorApi} from "./notes-for-pilot/editor";

const { Content } = Layout;

// TODO: Replace me with a real interface
export type FlightScheduleEditProps = {
    orgData?: OrgDataProps
};

type TEMP_TYPE_Flight = any;

type TEMP_TYPE_Flight_Query = {
    getFlight: TEMP_TYPE_Flight
};
type TEMP_TYPE_Pax = {
    _id: string
};
type TEMP_TYPE_Cgo = {
    _id: string
};
type TEMP_TYPE_Entity = TEMP_TYPE_Pax | TEMP_TYPE_Cgo
type TEMP_TYPE_Location = {
    _id: string
}

const FLIGHT = gql`
query Flight($_id: ID!){
    getFlight(_id: $_id){
        ...ScheduledFlightFragment
    }
}
${ScheduledFlightFragment}
`

const UPDATE_FLIGHT_FORM = gql`
mutation UpdateFlightForm($payload: FlightNodeInput!, $orgID: ID!){
    setFlight(payload: $payload, orgID: $orgID){
        ...ScheduledFlightFragment
    }
}
${ScheduledFlightFragment}
`

const UPDATE_FLIGHT_LEGS = gql`
mutation UpdateFlightLegs($payload: FlightNodeInput!, $orgID: ID!){
    setFlight(payload: $payload, orgID: $orgID){
        ...ScheduledFlightFragment
    }
}
${ScheduledFlightFragment}
`

const UPDATE_FLIGHT_PASSENGER = gql`
mutation UpdateFlightPassenger($flight: FlightNodeInput!, $orgID: ID!){
    setFlight(payload: $flight, orgID: $orgID){
        ...ScheduledFlightFragment
    }
}
${ScheduledFlightFragment}
`

const DELETE_FLIGHT = gql`
mutation DeleteFlight($_id: ID! $tpID: ID!){
    deleteFlight(_id: $_id tpID: $tpID){
        ...ScheduledFlightFragment
    }
}
${ScheduledFlightFragment}
`
const SCHEDULED_TO_EXPECTED = gql`
mutation ScheduledToExpected($flightID: String!){
    setScheduledToExpected(flightID: $flightID){
        ...ScheduledFlightFragment
    }
}
${ScheduledFlightFragment}
`

const GET_LOCATION = gql`
query GetLocation($_id: ID!){
    getLocation(_id: $_id){
        ...LocationFragment
    }
}
${LocationFragment}
`

const defaultRules = [
    {
        required: true,
        message: 'This field is required'
    }
]

interface FlightFormProps extends FormComponentProps, WithOrgDataProps {
    flightid: string,
    editorProps: any,
    data: TEMP_TYPE_Flight
}

const FlightForm_Internal: React.FC<FlightFormProps> = (props) => {
    const {
        form: {getFieldDecorator, validateFields, isFieldsTouched, getFieldValue, resetFields},
        data,
        flightid,
        orgData,
        editorProps
    } = props;

    const client = useApolloClient();

    const defaultFieldProps = {
        size: 'default' as ('small' | 'default' | 'large')
    }

    const getPilotValue = (data) => {
        const pilot = data && data.pilot;
        if (!pilot)
        return null
        const pilotName = pilot.name;
        let name = '';
        if (pilotName){
            name = `${pilotName.lastName || ''}, ${pilotName.firstName || ''}`
        }
        return {
            key: pilot._id,
            label: name
        }
    }

    const getCoPilotValue = (data) => {
        const pilot = data && data.copilot;
        if (!pilot)
        return null
        const pilotName = pilot.name;
        let name = '';
        if (pilotName){
            name = `${pilotName.lastName || ''}, ${pilotName.firstName || ''}`
        }
        return {
            key: pilot._id,
            label: name
        }
    }

    const formFields = [
        {
            label: 'Scheduled Flight Date',
            key: 'scheduledFlightDate',
            component: <DatePicker disabled {...{
                ...defaultFieldProps,
                style: {
                    width: '100%'
                }
            }} disabledDate={current => current < moment().endOf('day')} format="YYYY-MM-DD" />,
            getValue: (data) => moment(data.scheduledFlightDate),
            options: {
                rules: defaultRules
            }
        },
        {
            label: 'Flight Designation',
            key: 'desig',
            component: <Input {...defaultFieldProps} />,
            options: {
                rules: defaultRules
            }
        },
        {
            label: 'Controlling Heliport',
            key: 'lastKnownController',
            component: <LocationOnshoreSelect disabled labelInValue />,
            options: {
                rules: defaultRules
            },
            getValue: (data) => {
                const lastKnownController = data['lastKnownController']
                if (!lastKnownController){
                    return null;
                }
                return {
                    key: lastKnownController._id,
                    label: lastKnownController.name
                }
            }
        },
        {
            label: 'Departure',
            key: 'departureID',
            component: <LocationSelect labelInValue />,
            options: {
                rules: defaultRules
            },
            getValue: (data) => {
                let departure = data['departureID']
                if (!departure){
                    if(data.legsArray && data.legsArray[0]){
                        departure = data.legsArray[0].departureID
                        console.log(data.legsArray[0].departureID)
                    }else {
                        return null;
                    }
                }
                return {
                    key: departure._id,
                    label: departure.name
                }
            }
        },
        {
            label: 'Aircraft',
            key: 'aircraftID',
            component: <AircraftSelect labelInValue {...defaultFieldProps} />,
            options: {
                rules: defaultRules
            },
            getValue: (data) => {
                const aircraft = data && data.aircraftID;
                if (!aircraft)
                return null;
                return {
                    key: aircraft._id,
                    label: aircraft.tailNum
                }
            }
        },
        {
            label: 'Contract',
            key: 'contractID',
            component: <ContractSelect
                            labelInValue
                            orgType={'customer'}
                            locationID={data['lastKnownController'] && data['lastKnownController']._id}
                            {...defaultFieldProps} />,
            options: {
                rules: defaultRules
            },
            getValue: (data) => {
                const contract = data && data.contract;
                if (!contract)
                return null;
                return {
                    key: contract._id,
                    label: contract.name
                }
            }
        },
        {
            label: 'Pilot',
            key: 'pilotID',
            component: <PilotSelect labelInValue {...defaultFieldProps} />,
            getValue: getPilotValue
        },
        {
            label: 'Co-Pilot',
            key: 'copilotID',
            component: <PilotSelect labelInValue {...defaultFieldProps} />,
            getValue: getCoPilotValue
        }
    ]
    return (
        <Mutation mutation={UPDATE_FLIGHT_FORM} onError={error => {
            console.error(error);
            message.error('Failed to update flight')
        }}>
        {
            (mutate) => (
                <Form layout="horizontal" onBlur={() => {
                    validateFields((err, values) => {
                        if (!err && isFieldsTouched()){
                            const startMutation = () => {
                                let departure = values.departureID && {_id: values.departureID.key, name: values.departureID.label}
                                // if(!departure){
                                //     departure = getFlightDeparture(data)
                                // }
                                // const newLegs = JSON.stringify( Object.assign({}, paxListToFlightLegs(departure, data.paxIDList, data.cgoIDList)) );
                                const flManager = getFlightLegManagerFromFlightQuery(data, undefined, { id: departure?._id, name: departure?.name });
                                const newLegs = JSON.stringify( Object.assign({}, flManager.buildFlightLegs()) )
                                const variables = {
                                    payload: {
                                        _id: flightid,
                                        callSign: data.callSign,
                                        tpID: orgData.transporter._id,
                                        customerID: orgData.customer._id,
                                        desig: values.desig,
                                        scheduledFlightDate: values.scheduledFlightDate.format('YYYY-MM-DD'),
                                        pilotID: values.pilotID ? values.pilotID.key : undefined,
                                        copilotID: values.copilotID ? values.copilotID.key : undefined,
                                        aircraftID: values.aircraftID ? values.aircraftID.key : undefined,
                                        contractID: values.contractID ? values.contractID.key : undefined,
                                        departureID: departure && departure._id,
                                        lastKnownController: data['lastKnownController'] && data['lastKnownController']._id,
                                        legs: newLegs
                                    },
                                    orgID: orgData.getActiveOrgID()
                                }
                                const getPilotNameObj = (name) => {
                                    if(!name) return null
                                    const [ last, first ] = name.split(', ');
                                    return {
                                        __typename: 'Name',
                                        lastName: last,
                                        firstName: first
                                    }
                                }
                                const pilotName = getPilotNameObj(values.pilotID && values.pilotID.label);
                                const copilotName = getPilotNameObj(values.copilotID && values.copilotID.label);

                                let newPaxList = data.paxIDList.map((pax) => {
                                    let legPax = flManager.allPax.get(pax._id);
                                    return {
                                        ...pax,
                                        departureID: {
                                            __typename: 'Location',
                                            _id: legPax.departureID,
                                            name: flManager.getLocationName(legPax.departureID)
                                        },
                                        destinationID: {
                                            __typename: 'Location',
                                            _id: legPax.destinationID,
                                            name: flManager.getLocationName(legPax.destinationID)
                                        }
                                    }
                                })

                                let newCgoList = data.cgoIDList.map((cgo) => {
                                    let legCgo = flManager.allCgo.get(cgo._id);
                                    return {
                                        ...cgo,
                                        departureID: {
                                            __typename: 'Location',
                                            _id: legCgo.departureID,
                                            name: flManager.getLocationName(legCgo.departureID)
                                        },
                                        destinationID: {
                                            __typename: 'Location',
                                            _id: legCgo.destinationID,
                                            name: flManager.getLocationName(legCgo.destinationID)
                                        }
                                    }
                                })

                                return mutate({
                                    variables,
                                    optimisticResponse: {
                                        __typename: 'Mutation',
                                        setFlight: {
                                            __typename: 'FlightNode',
                                            _id: flightid,
                                            tpID: {
                                                __typename: 'Transporter',
                                                ...orgData.transporter
                                            },
                                            customerID: {
                                                __typename: 'Customer',
                                                ...orgData.customer
                                            },
                                            desig: values.desig,
                                            scheduledFlightDate: values.scheduledFlightDate.format('YYYY-MM-DD'),
                                            callSign: values.callSign,
                                            pilot: {
                                                __typename: 'Pilot',
                                                _id: values.pilotID && values.pilotID.key,
                                                name: pilotName
                                            },
                                            copilot: {
                                                __typename: 'Pilot',
                                                _id: values.copilotID && values.copilotID.key,
                                                name: copilotName
                                            },
                                            aircraftID: {
                                                __typename: 'Aircraft',
                                                _id: values.aircraftID && values.aircraftID.key,
                                                tailNum: values.aircraftID && values.aircraftID.label
                                            },
                                            contract: {
                                                __typename: 'Contract',
                                                _id: values.contractID && values.contractID.key,
                                                name: values.contractID && values.contractID.label
                                            },
                                            departureID: {
                                                __typename: 'Location',
                                                _id: (departure && departure._id) || data.departureID._id,
                                                name: (departure && departure.name) || data.departureID.name
                                            },
                                            legs: newLegs || data.legs,
                                            paxIDList: newPaxList,
                                            cgoIDList: newCgoList
                                        }
                                    }
                                })
                            }

                            client.query({
                                query: GET_LOCATION,
                                variables: {
                                    _id: values.departureID && values.departureID.key
                                }
                            })
                            .then(() => {
                                // TODO: Is this needed anymore?
                                startMutation()
                                .then(() => editorProps.refetch())
                            })
                            .catch(error => {
                                console.log(error);
                                startMutation()
                            })

                        }
                    })
                }}>
                    <Row type="flex" gutter={12}>
                        {formFields.map((field, i) => (
                            <Col key={i} style={{flex: 1}}>
                                <Form.Item label={field.label}>
                                    {getFieldDecorator(field.key, {
                                        ...field.options,
                                        initialValue: field.getValue ? field.getValue(data) : data[field.key]
                                    })(
                                        field.component
                                    )}
                                </Form.Item>
                            </Col>
                        ))}
                    </Row>
                </Form>
            )
        }
        </Mutation>
    )
}

const FlightForm = WithOrgData( Form.create<FlightFormProps>()( FlightForm_Internal ));

interface PrintoutButtonProps extends ButtonProps {
    flight: any,
    orgData?: any,
    cognitoUser?: any
}

var PrintoutButton: React.FC<PrintoutButtonProps> = ({flight, orgData, cognitoUser, ...restProps}) => {
    const [ fetchPrintout, fetching ] = useManifestPrintout('FlightWithLegs', 'Hess');
    const userAttr = cognitoUser.attributes;
    return (
        <Query
            query={gql`
                query GetCustomerManifestPrintout($id: ID!){
                    getCustomer(_id: $id){
                        _id
                        name
                        manifestPrintout {
                            type
                            layouts {
                                name
                                logo
                            }
                        }
                    }
                }
            `}
            variables={{ id: orgData.customer._id }}
        >
        {({ data }) => {
            const manifestPrintouts = safeGet(['getCustomer', 'manifestPrintout'], data) || [];
            const flightManifest = manifestPrintouts.find((m) => m.type === 'Flight'); 
            const hessLayout = flightManifest && flightManifest.layouts.find((l) => l.name === 'Hess');
            const logoURL = hessLayout && hessLayout.logo;
            const handleClick = () => {
                if (!flight){
                    return;
                }
                const legs = flight.legsArray || [];
                const body = {
                    issuedBy: `${userAttr.given_name} ${userAttr.family_name}`,
                    orgName: orgData.customer.name,
                    logoURL: logoURL,
                    desig: flight.desig,
                    departure: flight.departureID && flight.departureID.name,
                    aircraftNo: flight.aircraftID && flight.aircraftID.tailNum,
                    scheduledFlightDate: flight.scheduledFlightDate,
                    legs: legs.map((leg) => {
                        return {
                            departure: leg.departure,
                            destination: leg.destination,
                            passengers: leg.paxIDs.map((pax) => {
                                return {
                                    _id: pax._id,
                                    lastName: pax.lastName,
                                    firstName: pax.firstName,
                                    employer: pax.employerID && pax.employerID.name,
                                    chargeCode: pax.chargeCode && pax.chargeCode.toUpperCase(),
                                    paxWeight: pax.paxWeight,
                                    bagWeight: pax.bagWeight,
                                    departure: pax.departureID && pax.departureID.name,
                                    destination: pax.destinationID && pax.destinationID.name,
                                    dob: pax.personID && pax.personID.dob,
                                    nation: pax.personID && pax.personID.nation,
                                    credentials: pax.personID && pax.personID.credentials
                                }
                            }),
                            cargo: leg.cgoIDs.map((cgo) => {
                                return {
                                    _id: cgo._id,
                                    name: cgo.name,
                                    weight: cgo.weight,
                                    departure: cgo.departureID && cgo.departureID.name,
                                    destination: cgo.destinationID && cgo.destinationID.name,
                                    attentionTo: cgo.attentionTo,
                                    deliveredBy: cgo.deliveredBy,
                                    transitType: cgo.transitType
                                }
                            })
                        }
                    })
                }
                fetchPrintout(body, true, `${orgData.customer.name} Flight Manifest.xlsx`)
                .catch((err) => {
                    message.error('Failed to download printout', 3)
                    .then(() => message.error(err.message), null)
                })
            }
            return (
                <Button {...restProps} onClick={handleClick} loading={fetching}>Download Printout</Button>
            )
        }}
        </Query>
    )
}

PrintoutButton = WithOrgData( withCognitoUser( PrintoutButton ) )

const INIT_STATE = {
    selectedLeg: 0,
    selectedLegs: [],
    manualAssign: "auto",
    passengerDrawerOpen: false,
    locationsDrawerOpen: false,
    fuelStopDrawerOpen: false,
    passengerModalOpen: false,
    deleting: false,
    manualLegs: []
}

interface RouterParams {
    id: string
}

interface ReviewChangesProps {
    entitiesToRemove: TEMP_TYPE_Entity[],
    entitiesToModify: TEMP_TYPE_Entity[],
    entitiesToAdd: TEMP_TYPE_Entity[]
}

const ReviewChanges: React.FC<ReviewChangesProps> = (props) => {
    function renderEntityList(title: string, icon: React.ReactNode, rowColor: string, entities: TEMP_TYPE_Entity[]){
        if (!entities || !entities.length) return null;

        const columns = createScheduledEntityColumns(null, { reviewMode: true });

        columns.unshift({
            title: '',
            key: 'icon',
            render: () => icon
        })

        return <div>
            <h4>{title}</h4>
            <ETable
                className='mc-table mc-scheduling-leg-pax'
                dataSource={entities}
                columns={columns}
                rowKey={(record: TEMP_TYPE_Entity) => record._id}
                onRow={() => ({ style: { backgroundColor: rowColor} })}
                size="small"
                pagination={false}
            />
        </div>
    }

    return <>
        {renderEntityList(`Removing ${props.entitiesToRemove.length} items`, <Icon type="close" style={{ color: '#ff0000' }} />, '#ff00003d', props.entitiesToRemove)}
        {renderEntityList(`Modifying ${props.entitiesToModify.length} items`, <Icon type="edit" style={{ color: '#0084ff' }} />, '#0084ff3d', props.entitiesToModify)}
        {renderEntityList(`Adding ${props.entitiesToAdd.length} items`, <Icon type="plus" style={{ color: '#00ff00' }} />, '#00ff003d', props.entitiesToAdd)}
    </>
}

const FlightScheduleEdit_Internal: React.FC<FlightScheduleEditProps> = (props) => {
    const [ state, _setState ] = useState(INIT_STATE);
    const orgData = useContext(OrgDataContext);
    const history = useHistory();
    const routerParams = useParams<RouterParams>();
    const client = useApolloClient();
    const drawerBreakpoints = useBreakpoints({
        widthOffset: 24
    });

    const entityMultiSelect = useMultiSelect<TEMP_TYPE_Entity>({
        primaryKey: '_id'
    })

    // ---------------------------------------------------------------------------------
    // Queries and mutations
    // ---------------------------------------------------------------------------------
    const { data, loading, error, refetch } = useQuery<TEMP_TYPE_Flight_Query>(FLIGHT,
        {
            variables: {
                _id: routerParams.id
            },
            fetchPolicy: "cache-and-network",
            pollInterval: 10000
        }
    )

    const allEntities = useMemo(() =>
        [...castArray(data?.getFlight?.paxIDList), ...castArray(data?.getFlight?.cgoIDList)]
    , [data]);

    const notesForPilotApi = useNotesForPilotEditorApi({
        entities: allEntities
    });

    function setState(newState: Partial<typeof INIT_STATE>){
        _setState(currState => ({...currState, ...newState}))
    }

    const paxCgoAdderState = ScheduledPaxCgoAdder.useState(routerParams.id, data?.getFlight?.scheduledFlightDate, {
        onFlightUpdated: () => setState({ passengerDrawerOpen: false })
    });

    const [ updateFlightMutation, updateFlightMutationResult ] = useMutation(UPDATE_FLIGHT_PASSENGER);
    const [ updateFlightLegsMutation, updateFlightLegsResult ] = useMutation(UPDATE_FLIGHT_LEGS);

    // ---------------------------------------------------------------------------------

    function openPassengerDrawer(){ setState({passengerDrawerOpen: true }) }
    function closePassengerDrawer(){ setState({passengerDrawerOpen: false }) }

    function openLocationsDrawer(){ setState({locationsDrawerOpen: true })}
    function closeLocationsDrawer(){ setState({locationsDrawerOpen: false }) }

    function openFuelStopDrawer(){ setState({fuelStopDrawerOpen: true }) }
    function closeFuelStopDrawer(){ setState({fuelStopDrawerOpen: false }) }

    function deleteFlight() {
        const { _id } = data.getFlight;
        setState({ deleting: true })
        client.mutate({
            mutation: DELETE_FLIGHT,
            variables: {
                _id,
                tpID: orgData.transporter._id
            }
        })
            .then(() => {
                history.push('/app/scheduling/flight')
            })
            .catch(error => {
                console.error(error);
                setState({ deleting: false });
                message.error(`Failed to delete flight: ${error.message}`);
            })
    }

    // Uses outdated method of computing flight legs. Used by manual assign editor.
    // TODO: Remove this function
    function removeEntitiesFromFlight(entities: TEMP_TYPE_Entity[]) {

        const entityIds = new Set(entities.map(e => e?._id));

        const { customer, transporter } = props.orgData;
        const { legsArray, cgoIDList, paxIDList, _id: flightid } = data.getFlight;

        function getId(entity: TEMP_TYPE_Entity){
            return entity._id;
        }

        const newPaxList = paxIDList
            .filter((pax: TEMP_TYPE_Pax) => !entityIds.has(pax?._id));
        const newCgoList = cgoIDList
            .filter((cgo: TEMP_TYPE_Cgo) => !entityIds.has(cgo?._id));

        const newPaxIDList = newPaxList.map(getId);
        const newCgoIDList = newCgoList.map(getId);

        var newLegs = [];
        if((newPaxList != null && newPaxList.length > 0) || (newCgoList != null && newCgoList.length > 0)){
            legsArray.forEach(leg => {
                newLegs.push(
                    paxOnFlightLeg(
                        leg,
                        leg.paxIDs.filter((pax: TEMP_TYPE_Pax) => !entityIds.has(pax._id)),
                        leg.cgoIDs.filter((cgo: TEMP_TYPE_Cgo) => entityIds.has(cgo._id))
                    )
                )
            });
        }

        var legsObj = null
        if (newLegs.length) legsObj = JSON.stringify(Object.assign({}, newLegs))

        const flightPayload = {
            _id: flightid,
            paxIDList: newPaxIDList,
            cgoIDList: newCgoIDList,
            customerID: customer._id,
            tpID: transporter._id,
            legs: legsObj
        }

        return updateFlightMutation({
            variables: {
                flight: flightPayload,
                orgID: props.orgData.getActiveOrgID()
            }
        })
            .then(() => message.success('Passenger successfully removed from flight'))
            .catch(error => {
                console.error(error);
                message.error('Failed to remove passenger from flight.')
            })
    }

    function removeEntitiesRecalLegs(entities: TEMP_TYPE_Entity[]){
        return new Promise((resolve, reject) => {
            const { customer, transporter } = props.orgData;
            const { paxIDList, cgoIDList, departureID, _id: flightid, legs: oldLegs } = data.getFlight;

            // Future me: test this in the debugger
            let flManager = getFlightLegManagerFromFlightQuery(data.getFlight);

            for (let entity of entities) {
                flManager.removePassenger(entity._id);
                flManager.removeCgo(entity._id);
            }

            // const newLocList = getLocationsFromPaxAndCgoList(getFlightDeparture(this.props.data.getFlight), newPaxList, newCgoList)
            // const oldLocList = getLocationsFromLegs(oldLegs)
            //If the locs are the same length, manually remove the pax so it doesn't recalculate the legs.
            //This equality may need to be changed in the future.
            // if(newLocList.length === oldLocList.length){
            //     return this.removePassengerFromFlight(id)
            // }
            var newLegs = flManager.buildFlightLegs();
            var legs  = JSON.stringify(Object.assign({}, newLegs))

            const flightPayload = {
                _id: flightid,
                paxIDList: flManager.getPaxIDList(),
                cgoIDList: flManager.getCgoIDList(),
                customerID: customer._id,
                tpID: transporter._id,
                legs
            }

            Modal.confirm({
                title: 'Review Changes',
                maskClosable: false,
                content: <ReviewChanges entitiesToRemove={entities} entitiesToModify={[]} entitiesToAdd={[]} />,
                width: 'fit-content',
                onOk: () => {
                    updateFlightMutation({
                        variables: {
                            flight: flightPayload,
                            orgID: props.orgData.getActiveOrgID()
                        }
                    })
                        .then(() => {
                            message.success('Successfully removed from flight');
                            refetch();
                            paxCgoAdderState.api.data.refetch();
                            resolve(true);
                        })
                        .catch(error => {
                            console.error(error);
                            message.error('Failed to remove from flight.');
                            reject(error);
                        })
                }
            })
        })
    }

    function removesAllPaxFromLegReOrder(locations: TEMP_TYPE_Location[]) {
        const { orgData } = props;
        const { _id: flightid } = data.getFlight;

        var newLegs = [];
        for(var i = 0; i < locations.length-1; i++){
            newLegs.push(createNewLeg(i, locations[i], locations[i+1]))
        }
        var legsObj = null
        if (newLegs.length) legsObj = JSON.stringify(Object.assign({}, newLegs))

        updateFlightLegsMutation({
            variables: {
                payload: {
                    _id: flightid,
                    paxIDList: [],
                    cgoIDList: [],
                    departureID: locations[0]._id,
                    legs: legsObj,
                    tpID: orgData.transporter._id,
                    customerID: orgData.customer._id
                },
                orgID: orgData.getActiveOrgID()
            },
            optimisticResponse: {
                __typename: 'Mutation',
                setFlight: {
                    __typename: 'FlightNode',
                    _id: flightid,
                    paxIDList: [],
                    cgoIDList: [],
                    legs: legsObj
                }
            }
        })
            .then(() => message.success('Flight legs successfully updated'))
            .catch(error => {
                console.error(error);
                message.error('Failed to update flight legs')
            })
    }

    function addFuelStops(locations: TEMP_TYPE_Location[]) {
        const { orgData } = props;
        const { _id: flightid, legsArray } = data.getFlight;

        var newLegs = [];
        for(var i = 0; i < locations.length-1; i++){
            newLegs.push(createNewLeg(i, locations[i], locations[i+1]))
        }

        var depIdx = 0
        var destIdx = 0

        legsArray.forEach(leg => {
            depIdx = newLegs.slice(destIdx).findIndex(newLeg => newLeg.departureID === leg.departureID) + destIdx
            destIdx = newLegs.slice(destIdx).findIndex(newLeg => newLeg.destinationID === leg.destinationID) + destIdx

            for(var i = depIdx; i <= destIdx; i++){
                var newLeg = {
                    ...leg,
                    paxIDs: leg.paxIDs.map(pax => pax._id),
                    cgoIDs: leg.cgoIDs.map(cgo => cgo._id),
                    order: i,
                    departureID: newLegs[i].departureID,
                    departure: newLegs[i].departure,
                    destinationID: newLegs[i].destinationID,
                    destination: newLegs[i].destination
                }
                delete newLeg.__typename
                newLegs[i] = newLeg
            }
        })

        var legsObj = null
        if (newLegs.length) legsObj = JSON.stringify(Object.assign({}, newLegs))

        updateFlightLegsMutation({
            variables: {
                payload: {
                    _id: flightid,
                    legs: legsObj,
                    tpID: orgData.transporter._id,
                    customerID: orgData.customer._id
                },
                orgID: orgData.getActiveOrgID()
            },
            optimisticResponse: {
                __typename: 'Mutation',
                setFlight: {
                    __typename: 'FlightNode',
                    _id: flightid,
                    legs: legsObj
                }
            }
        })
            .then(() => message.success('Flight legs successfully updated'))
            .catch(error => {
                console.error(error);
                message.error('Failed to update flight legs')
            })
    }

    function onLegsReorder(dragIndex: number, hoverIndex: number) {
        const { orgData } = props;
        const { _id: flightid, legs, paxIDList, cgoIDList, departureID } = data.getFlight;
        if (!legs) return null;

        let flManager = getFlightLegManagerFromFlightQuery(data.getFlight);

        const parsedLegs = Object.values(JSON.parse(legs));

        if (dragIndex === parsedLegs.length - 1){
            message.warn("The last flight leg cannot be reordered")
            return null
        }
        if (hoverIndex === parsedLegs.length -1){
            message.warn("The last flight leg must be a return to the flight departure location")
            return null
        }

        const destinations = getDestinationsFromLegs(parsedLegs);

        flManager.moveDestination(destinations[dragIndex]._id, destinations[hoverIndex]._id);

        const startSlice = dragIndex < hoverIndex ? dragIndex : hoverIndex;
        const endSlice = dragIndex > hoverIndex ? dragIndex : hoverIndex;
        const reorderedDestinations = [
            ...destinations.slice(0, startSlice),
            destinations[endSlice],
            ...destinations.slice(startSlice + 1, endSlice),
            destinations[startSlice],
            ...destinations.slice(endSlice + 1)
        ]

        let newAllList = [];

        const filteredDestinations = reorderedDestinations.filter((loc, idx, arr) => {
            if(idx !== arr.length -1){
                if(arr[idx+1]._id !== loc._id){
                    return loc;
                }
                return false
            }else {
                return loc;
            }
        });

        filteredDestinations.forEach(location => {
            const sortPaxByOrder = (a, b) => {
                return a.scheduledOrder - b.scheduledOrder
            }
            var newList = [];
            if(paxIDList && paxIDList.length > 0){
                newList = newList.concat(paxIDList.filter(pax => pax.destinationID._id === location._id));
            }
            if(cgoIDList && cgoIDList.length > 0){
                newList = newList.concat(cgoIDList.filter(cgo => cgo.destinationID._id === location._id));
            }
            const outbound = newList.filter(pax => pax.transitType === 'OUTBOUND').sort(sortPaxByOrder);
            const transfer = newList.filter(pax => pax.transitType === 'TRANSFER').sort(sortPaxByOrder);
            const inbound = newList.filter(pax => pax.transitType === 'INBOUND').sort(sortPaxByOrder);
            const sortedAll = [...outbound, ...transfer, ...inbound]
            newAllList.push(...sortedAll);
        })

        // @ts-ignore TODO: Remove ts-ignore
        newAllList = [...new Set(newAllList)];

        const newPaxList = newAllList.filter(pax => pax.classType === 'flytsuite.paxnode' );
        const newCgoList = newAllList.filter(cgo => cgo.classType === 'flytsuite.cgonode' );
        // const newPaxIDList = newPaxList.map(pax => pax._id);
        // const newCgoIDList = newCgoList.map(cgo => cgo._id);
        const newLegs = flManager.buildFlightLegs();
        let newLegsJson = JSON.stringify( Object.assign({}, newLegs) )

        let newLegsArray = newLegs.map(leg => {
            return {
                ...leg,
                paxIDs: leg.paxIDs.map(id => newPaxList.find(p => p._id === id)),
                cgoIDs: leg.cgoIDs.map(id => newCgoList.find(c => c._id === id))
            }
        })

        updateFlightLegsMutation({
            variables: {
                payload: {
                    _id: flightid,
                    // paxIDList: newPaxIDList,
                    // cgoIDList: newCgoIDList,
                    legs: newLegsJson,
                    tpID: orgData.transporter._id,
                    customerID: orgData.customer._id
                },
                orgID: orgData.getActiveOrgID()
            },
            optimisticResponse: {
                __typename: 'Mutation',
                setFlight: {
                    __typename: 'FlightNode',
                    _id: flightid,
                    // paxIDList: newPaxList,
                    // cgoIDList: newCgoList,
                    legs: newLegsJson,
                    legsArray: newLegsArray
                }
            }
        })
            .then(() => message.success('Flight legs successfully updated'))
            .catch(error => {
                console.error(error);
                message.error('Failed to update flight legs')
            })
    }

    function getSelectedLegsFromLegs(legs: FlightLeg[]) {
        var arr = [];
        state.selectedLegs.sort().forEach(leg => {
            arr.push(legs[leg])
        })

        return arr;
    }

    function getLocationsFromLegs(legs: FlightLeg[]) {
        var arr = [];

        legs.forEach(leg => {
            arr.push({
                key: leg.departureID,
                label: leg.departure
            })
        })

        if(legs.length > 0){
            arr.push({
                key: legs[legs.length-1].destinationID,
                label: legs[legs.length-1].destination
            })
        }


        return arr
    }

    async function removeRedundantDestination(locID: string){
        let flManager = getFlightLegManagerFromFlightQuery(data.getFlight);

        if (!flManager.removeLocationIfRedundant(locID)){
            return;
        }

        let legs = flManager.buildFlightLegs();

        let flightid = data.getFlight._id;

        try{
            var res = await updateFlightLegsMutation({
                variables: {
                    payload: {
                        _id: flightid,
                        legs: JSON.stringify( Object.assign({}, legs) ),
                        tpID: props.orgData.transporter._id,
                        customerID: props.orgData.customer._id
                    },
                    orgID: props.orgData.getActiveOrgID()
                },
                optimisticResponse: {
                    __typename: 'Mutation',
                    setFlight: {
                        __typename: 'FlightNode',
                        _id: flightid
                    }
                }
            })
        }
        catch(err){
            console.error(err);
            message.error("Failed to remove redundant destination.");
            return;
        }
        message.success('Successfully removed redundant destination.');
        refetch();
        return res;
    }

    function renderContent(){
        const { orgData } = props;

        if (error) return <Alert type="error" message="Failed to load flight" description={error.message} showIcon />

        if (loading && !data) return <LoadingContent />
        if (!data || !data.getFlight) return <h2>No data returned</h2>
        if (data.getFlight.tpID._id !== orgData.transporter._id) {
            return <h2>Transportation providers do not match</h2>
        }
        const legs: FlightLeg[] = (data.getFlight.legs && Object.values(JSON.parse(data.getFlight.legs))) || []
        const selectedLeg = legs?.[state.selectedLeg];

        let flManager = getFlightLegManagerFromFlightQuery(data.getFlight);

        return (
            <React.Fragment>
                <FlightForm flightid={routerParams.id} data={data.getFlight} editorProps={props}/>
                <div style={{position: 'relative'}}>
                    <Row type="flex" style={{position: 'absolute', right: 0, top: 0, zIndex: 2, alignItems: 'center'}}>
                        {entityMultiSelect.selectedItems.length > 0 ?
                            <span>
                            {entityMultiSelect.selectedItems.length} selected
                            <Button
                                style={{marginInline: 6}}
                                onClick={() => entityMultiSelect.clearAll()}
                                size="small"
                            >Clear</Button>
                            </span>
                            : null}
                        <Dropdown
                            overlay={<Menu>
                                <Menu.Item key="all" onClick={() => entityMultiSelect.setSelectedItems(allEntities)}>Select
                                    All</Menu.Item>
                                <Menu.Item key="all" onClick={() => entityMultiSelect.clearAll()}>Select
                                    None</Menu.Item>
                                <Menu.SubMenu title="By Group">
                                    {renderGroupNameMenuItems(entityMultiSelect, allEntities)}
                                </Menu.SubMenu>
                            </Menu>}
                        >
                            <Button style={{marginRight: 6}}>Select... <Icon type="check-square"/></Button>
                        </Dropdown>
                        <Button
                            type="danger"
                            disabled={!entityMultiSelect?.selectedItems.length || updateFlightMutationResult.loading}
                            onClick={() => entityMultiSelect.selectedItems.length > 0 && removeEntitiesRecalLegs(entityMultiSelect.selectedItems)
                                .then(() => entityMultiSelect.clearAll())}
                        >
                            Remove Selected
                            <Icon type="delete"/>
                        </Button>
                    </Row>
                    <Tabs
                        type="card"
                        defaultActiveKey="auto"
                        onChange={tab => setState({manualAssign: tab})}
                        className="scheduling-flight-edit-legs-tabs"
                    >
                        <Tabs.TabPane key="auto" tab="Auto Assign">
                            <Card>
                                <div style={{margin: '-12px'}}>
                                    <Row gutter={24}>
                                        <Col span={8} className="scheduling-flight-edit-legs-col-left">
                                            <FlightScheduleLegs
                                                selectedLeg={state.selectedLeg}
                                                data={legs}
                                                selectedEntities={entityMultiSelect.selectedItems}
                                                onAddLegs={openPassengerDrawer}
                                                onLegChange={(index: number) => setState({selectedLeg: index})}
                                                checkLegIsRedundant={(leg: FlightLeg) => flManager.isLocationRedundant(leg.destinationID)}
                                                onReorder={onLegsReorder}/>
                                        </Col>
                                        <Col span={16} style={{minHeight: '30rem'}}>
                                            <Spin indicator={<Icon type="loading"/>}
                                                  spinning={updateFlightMutationResult.loading}>
                                                <FlightScheduleLegPax
                                                    flight={data.getFlight}
                                                    selectedLegIndex={state.selectedLeg}
                                                    departureName={selectedLeg?.departure}
                                                    destinationName={selectedLeg?.destination}
                                                    multiSelect={entityMultiSelect}
                                                    events={{
                                                        onRemoveRedundantLeg: selectedLeg && flManager.isLocationRedundant(selectedLeg.destinationID) ?
                                                            () => removeRedundantDestination(selectedLeg.destinationID) : undefined
                                                    }}
                                                />
                                            </Spin>
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Tabs.TabPane>
                        <Tabs.TabPane key="manual" tab="Manual Assign">
                            <Card>
                                <div style={{margin: '-12px'}}>
                                    <Row gutter={24}>
                                        <Col span={8} className="scheduling-flight-edit-legs-col-left"
                                             style={{minHeight: '30rem'}}>
                                            <FlightManualScheduleLegs
                                                selectedLegs={state.selectedLegs}
                                                data={legs}
                                                selectedEntities={entityMultiSelect.selectedItems}
                                                onAddLegs={openPassengerDrawer}
                                                onAddLocations={openLocationsDrawer}
                                                onAddFuelStop={openFuelStopDrawer}
                                                onLegChange={(index) => {
                                                    var arr = state.selectedLegs;
                                                    const idx = arr.indexOf(index);
                                                    if (idx > -1) {
                                                        arr.splice(idx, 1);
                                                        setState({selectedLegs: arr})
                                                    } else {
                                                        setState({selectedLegs: [...state.selectedLegs, index]})
                                                    }
                                                }}/>
                                        </Col>
                                        <Col span={16} style={{minHeight: '30rem'}}>
                                            <FlightManualScheduleLegPax
                                                selectedLegs={getSelectedLegsFromLegs(legs)}
                                                multiSelect={entityMultiSelect}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </Card>
                        </Tabs.TabPane>
                    </Tabs>
                </div>
                {notesForPilotApi.getNotes().size > 0 ? (
                    <>
                        <h3 style={{marginTop: 16}}>
                            Notes For Pilot
                            <span style={{ marginLeft: 6 }}>
                                <Typography.Text
                                    type="secondary"
                                >({notesForPilotApi.getNotes().size} Notes)</Typography.Text>
                            </span>
                        </h3>
                        <NotesForPilotEditor
                            {...notesForPilotApi.getComponentProps()}
                            readOnly
                        />
                    </>
                ) : (
                    <>
                        <h3 style={{marginTop: 16}}>
                            Notes For Pilot
                        </h3>
                        <Typography.Paragraph type="secondary">No data</Typography.Paragraph>
                    </>
                )}
                <Drawer
                    headerStyle={{
                        padding: 12
                    }}
                    bodyStyle={{
                        padding: 12
                    }}
                    title={
                        <>
                            <div
                                style={{
                                    marginBottom: 12,
                                    display: drawerBreakpoints.max('large') ? 'block' : 'none'
                                }}
                            >
                                <h3 style={{margin: 0, fontSize: '1rem'}}>Select PAX/CGO to add to
                                    <span style={{marginLeft: 4}}
                                          className="mc-text-highlight">{data?.getFlight?.desig}</span>
                                </h3>
                            </div>
                            <Row
                                type="flex"
                                align="middle"
                                gutter={10}
                            >
                                <Col
                                    style={{
                                        flex: 1,
                                        display: drawerBreakpoints.min('large') ? 'block' : 'none'
                                    }}
                                >
                                    <h3 style={{margin: 0, fontSize: '1rem'}}>Select PAX/CGO to add to
                                        <span style={{marginLeft: 4}}
                                              className="mc-text-highlight">{data?.getFlight?.desig}</span>
                                    </h3>
                                </Col>
                                <Col>
                                    {paxCgoAdderState.render.searchTypeSelect()}
                                </Col>
                                <Col>
                                    {paxCgoAdderState.render.searchInput()}
                                </Col>
                                <Col style={{marginRight: '2rem'}}>
                                    {paxCgoAdderState.render.addButton()}
                                </Col>
                            </Row>
                        </>
                    }
                    visible={state.passengerDrawerOpen}
                    closable
                    width={drawerBreakpoints.max('large') ? '100%' : 1000}
                    onClose={closePassengerDrawer}
                    className="ant-drawer-flex"
                >
                    <Typography.Paragraph type="secondary">
                        View legs and PAX/CGO by clicking on the rows
                    </Typography.Paragraph>
                    <Row type="flex" style={{alignItems: 'baseline'}}>
                        <Col style={{flex: 1,}}>
                            <strong>
                                        <span
                                            style={{
                                                marginRight: 4
                                            }}
                                        >{paxCgoAdderState.api.data.groups.get().length}</span>
                                {pluralize('Group', paxCgoAdderState.api.data.groups.get().length)}
                            </strong>
                        </Col>
                        <Col>
                            <Button
                                type="link"
                                onClick={paxCgoAdderState.api.action.collapse.toggleAll}
                                style={{
                                    paddingLeft: 0,
                                    paddingRight: 0
                                }}
                            >
                                {paxCgoAdderState.api.action.collapse.isAllExpanded() ? (
                                        "Collapse All"
                                    ) :
                                    (
                                        "Expand All"
                                    )}
                            </Button>
                        </Col>
                    </Row>
                    {paxCgoAdderState.render.filtersAppliedAlert()}
                    {paxCgoAdderState.render.errorAlert()}
                    <ScheduledPaxCgoAdder
                        {...paxCgoAdderState.componentProps}
                    />
                    {paxCgoAdderState.render.paxModal()}
                    {paxCgoAdderState.render.isnDrawer()}
                </Drawer>
                {/*<AddPaxToFlightDrawer*/}
                {/*    visible={state.passengerDrawerOpen}*/}
                {/*    flightdate={data.getFlight.scheduledFlightDate}*/}
                {/*    flightid={routerParams.id}*/}
                {/*    lastKnownController={data.getFlight.lastKnownController._id}*/}
                {/*    manualAssign={state.manualAssign === "manual"}*/}
                {/*    selectedLegs={state.selectedLegs}*/}
                {/*    closable*/}
                {/*    onClose={closePassengerDrawer}/>*/}
                <AddLegsToFlightDrawer
                    visible={state.locationsDrawerOpen}
                    flightdate={data.getFlight.scheduledFlightDate}
                    flightid={routerParams.id}
                    legs={getLocationsFromLegs(data.getFlight.legsArray)}
                    onLegsReorder={removesAllPaxFromLegReOrder}
                    closable
                    onClose={closeLocationsDrawer}/>
                <AddFuelStopToFlightDrawer
                    visible={state.fuelStopDrawerOpen}
                    flightdate={data.getFlight.scheduledFlightDate}
                    flightid={routerParams.id}
                    legs={getLocationsFromLegs(data.getFlight.legsArray)}
                    onAddFuelStops={addFuelStops}
                    closable
                    onClose={closeFuelStopDrawer}/>
            </React.Fragment>
        )
    }

    const checkIfToday = (date) => {
        var d = new Date()
        var today = d.getFullYear() + "-" + (d.getMonth()+1+"").padStart(2, "0")+"-" + (d.getDate()+"").padStart(2, "0")
        return date === today
    }

    const mainHeaderProps: MainHeaderProps = {
        routes: [
            {
                path: '/app/scheduling/flight',
                title: 'Scheduled Flights',
                breadcrumbName: 'Scheduled Flights'
            },
            {
                path: null,
                breadcrumbName: loading ? <Spin /> : data && data.getFlight && data.getFlight.desig
            }
        ],
        actions: (
            <React.Fragment>
                <Row type="flex" gutter={12}>
                    <Col>
                        <Popconfirm title="Are you sure you want to delete this flight?" okText="Delete" onConfirm={deleteFlight} placement="leftTop">
                            <Button loading={state.deleting} type="danger">Delete Flight</Button>
                        </Popconfirm>
                    </Col>
                    <Col>
                        <PrintoutButton
                            flight={data && data.getFlight}
                            disabled={loading || Boolean(error) || !data || !data.getFlight}
                        />
                    </Col>
                    <Col>
                        <Button onClick={() => {
                            var isToday = checkIfToday(data.getFlight.scheduledFlightDate)
                            if(isToday && data.getFlight.legsArray && data.getFlight.legsArray.length > 0){
                                if((data.getFlight.paxIDList && data.getFlight.paxIDList.length > 0) || (data.getFlight.cgoIDList && data.getFlight.cgoIDList.length > 0)){
                                    const flightID = data.getFlight._id
                                    client.mutate({
                                        mutation: SCHEDULED_TO_EXPECTED,
                                        variables: {
                                            flightID: flightID
                                        }
                                    })
                                        .then(() => {
                                            history.push('/app/scheduling/flight')
                                        })
                                        .catch(error => {
                                            console.error(error);
                                            message.error(`Failed to make flight active or passengers expected: ${error.message}`);
                                        })
                                }else {
                                    history.push("app/scheduling/flight")
                                }
                            }else {
                                history.push("app/scheduling/flight")
                            }
                        }} type="primary">Finish</Button>
                    </Col>
                </Row>
            </React.Fragment>
        )
    }
    return (
        <MainLayout>
            <MainHeader {...mainHeaderProps} />
            <Content style={{ overflow: 'auto', padding: '1rem'}}>
                {renderContent()}
            </Content>
        </MainLayout>
    )
}

const NotAuth = () => (
    <MainLayout>
        <NotAuthorized />
    </MainLayout>
)

const FlightScheduleEdit = props => (
    <UserGroup.Flytsuite.Schedule.Flight renderNotAuthorized={<NotAuth />}>
        <FlightScheduleEdit_Internal {...props} />
    </UserGroup.Flytsuite.Schedule.Flight>
)

export default withRouter( WithOrgData( FlightScheduleEdit ) )