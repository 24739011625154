import { Alert, Input, Select, Tooltip } from 'antd';
import { SelectProps } from 'antd/lib/select';
import React, { LegacyRef, useState } from 'react';
import { SearchSelectWithData } from '../SearchSelect';
import LocationQuery from './LocationQuery';

const { Option } = Select;

export interface LocationSelect2Props extends SelectProps {
    onChange?: (value: any) => void,
    limit?: number,
    deleted?: boolean,
    type?: 'ONSHORE' | 'OFFSHORE',
    includeOneOff?: boolean,

    /** Filter locations that have DataFlyt hardware installed.
     * If true, it will only include locations that have hardware.
     * If false, it will not show locations with hardware.
     * If left null/undefined, the filter will not apply.*/
    hasDataflytHardware?: boolean
}

const LocationSelect2: React.FC<LocationSelect2Props> = React.forwardRef((props, ref: LegacyRef<SearchSelectWithData>) => {
    const [ state, _setState ] = useState({
        value: undefined,
        searchValue: null
    })

    function setState(newState) { _setState({ ...state, ...newState }) }

    function getValue(){
        if (props.value){
            if (state.value !== props.value){
                setState({ value: props.value });
            }
            return props.value
        }
        return undefined
    }

    function handleChange(value){
        setState({ value });
        props?.onChange(value)
    }

    function getLocName(loc: any){
        return loc.deletedTs ? "*" + loc.name + (loc.field && loc.block ? " (" + loc.block + "-" + loc.field + ")" : ""): loc.name + (loc.field && loc.block ? " (" + loc.block + "-" + loc.field + ")" : "") ;
    }

    let searchFields = {}

    if (String(state.searchValue).includes(':')){
        // Area/block mode
        let areablock = String(state.searchValue).split(':')
        searchFields = {
            area: areablock[0],
            block: areablock[1]
        }
    }
    else
    {
        // Name mode
        searchFields = {
            name: state.searchValue
        }
    }

    const variables = {
        limit: props.limit,
        filter: {
            type: props.type,
            deleted: props.deleted,
            includeOneOff: props.includeOneOff,
            hasDataflytHardware: props.hasDataflytHardware,
            ...searchFields
        }
    }
    var placeholderMsg = props.placeholder;
    if (!props.placeholder){
        switch (props.type) {
            case 'ONSHORE':
                placeholderMsg = 'Search for an onshore location.';
                break;
            case 'OFFSHORE':
                placeholderMsg = 'Search for an offshore location.';
                break;
            default:
                placeholderMsg = 'Search for a location.';
                break;
        }
    }
    return (
        <Tooltip title={placeholderMsg}>
            <SearchSelectWithData
                {...props}
                ref={ref}
                query={LocationQuery}
                variables={variables}
                value={getValue()}
                getData={(data) => data && data.locations && data.locations.docs}
                onDataItem={item => <Option key={item._id}>
                    {getLocName(item)}
                    </Option>}
                onChange={handleChange}
                onSearch={(searchValue) => setState({ searchValue })}
                placeholder={placeholderMsg}
                showAction={["focus", "click"]}
                dropdownMatchSelectWidth={false}
                dropdownStyle={{ width: '15rem' }}
                dropdownRender={(menu) => {
                    return <div>
                        <Alert style={{ margin: '0.25rem' }} message="Type a colon (:) to search by area/block. e.g. AB:123" />
                        {menu}
                    </div>
                }}
            />
        </Tooltip>
    );
});

LocationSelect2.defaultProps = {
    limit: 100,
    includeOneOff: false
}
 
export default LocationSelect2;