import React from 'react';
import {Row, Col, Button, Table, Icon, Typography, Tooltip, Checkbox, Badge} from 'antd';
import { DraggableBodyRow } from '../../TableDragSorting';
import ETable from 'components/enchanced-antd-table';
import {SelectedEntityCounter} from "components/flights/scheduling/FlightScheduleUtils";

class FlightScheduleLegs extends React.Component {
    render(){
        const columns = [
            {
                title: '#',
                key: '#',
                render: (_, __, index) => index + 1
            },
            {
                title:  'Dep',
                key: 'dep',
                dataIndex: 'departure'
            },
            {
                title: 'Dest',
                key: 'dest',
                dataIndex: 'destination'
            },
            {
                title: 'Pax',
                key: 'pax',
                dataIndex: 'paxCount'
            },
            {
                title: 'Bags',
                key: 'bags',
                dataIndex: 'bagCount'
            },
            {
                title: 'CGO',
                key: 'cgo',
                dataIndex: 'cgoCount'
            },
            {
                title: 'Total Wt',
                key: 'totalWt',
                render: (_, record) => Number(record.paxWeight) + Number(record.bagWeight) + Number(record.cgoWeight)
            },
            {
                title: '',
                key: 'redundantWarning',
                render: (_, record) => {

                    // Selected entities for just this leg
                    let legSelectedEntities = this.props.selectedEntities
                        .filter(e => record.paxIDs?.includes(e._id) || record.cgoIDs?.includes(e._id))

                    return (
                        <>
                            {this.props.checkLegIsRedundant?.(record) ?
                                <Tooltip title={`No PAX/CGO are planned to visit ${record.destination}. Removal is recommended.`}>
                                    <Typography.Text type='warning'><Icon type='warning' /></Typography.Text>
                                </Tooltip>
                                : null}
                            <SelectedEntityCounter legSelectedEntities={legSelectedEntities} />
                        </>
                    )
                }
            }
        ]
        const { title = 'Flight Legs', onAddLegs, data, selectedLeg = 0, onLegChange } = this.props;
        return (
            <React.Fragment>
                <Row type="flex" style={{marginBottom: '1rem'}}>
                    <Col style={{flex: 1}}>
                        <h3 style={{margin: 0}}>{title}</h3>
                    </Col>
                    {onAddLegs && (
                        <Col>
                            <Button type="primary" onClick={onAddLegs}>Add Flight Legs</Button>
                        </Col>
                        )
                    }
                </Row>
                {!data.length && (
                    <h3 style={{marginTop: '8rem', textAlign: 'center', color: 'grey'}}>Create new flight legs by adding scheduled passengers</h3>
                )}
                {data.length ? (
                    <ETable 
                        className="mc-table draggable-table"
                        dataSource={data} 
                        columns={columns} 
                        rowKey={(_, index) => index} 
                        size="small" 
                        pagination={false}
                        components={!this.props.disabled ? {
                            body: {
                                row: DraggableBodyRow
                            }
                        } : undefined}
                        onRow={(row, index) => {
                            const rowProps = {
                                moveRow: (dragIndex, hoverIndex) => this.props.onReorder && !this.props.disabled && this.props.onReorder(dragIndex, hoverIndex),
                                onClick: () => onLegChange && onLegChange(index),
                                index
                            }
                            if (index === selectedLeg){
                                return {
                                    ...rowProps,
                                    className: 'selected'
                                }
                            }
                            return {
                                ...rowProps,
                                style: {
                                    cursor: 'pointer'
                                }
                            }
                        }}
                        />
                ) : null
                }
            </React.Fragment>
        )
    }
}

export default FlightScheduleLegs