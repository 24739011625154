import { Alert, Button, Col, DatePicker, Divider, Icon, Input, Layout, Radio, Row, Spin } from 'antd';
import { castArray } from 'common/util';
import ButtonLink from 'components/ButtonLink';
import MainHeader from 'components/scheduling/MainHeader';
import MainLayout from 'components/scheduling/MainLayout';
import { UserGroup, withUserGroups } from 'components/userGroup';
import WithOrgData, { WithOrgDataProps } from 'components/WithOrgData';
import gql from 'graphql-tag';
import moment from 'moment';
import React, {useContext, useState} from 'react';
import { useApolloClient, useQuery } from 'react-apollo';
import MonthSelect from '../calendar/monthselect';
import { ScheduledGroup } from '../interface';
import { usePersistentDateRange } from '../util';
import ScheduledGroupsCalendar from './calendar';
import GroupList from './list';
import './style.less';
import {LocationFragment} from "../../../Queries/Location";
import {OrgDataContext} from "../../../context/orgData";
import {Queries} from "./graphql";

const { Content } = Layout;

const { RangePicker } = DatePicker;

const dividerStyle = {
    height: '3rem',
    margin: '0 1rem'
}

export interface ScheduledGroupsListProps extends WithOrgDataProps {
    onGroupClicked?(group: { date: string, name: string, lkc: string, departureID: string, destinationID: string })
}

function formatDate(date: moment.Moment){
    return date.format('YYYY-MM-DD')
}

const ScheduledGroupsList: React.FC<ScheduledGroupsListProps> = (props) => {

    const [ calValue, setCalValue ] = useState(moment());
    // const [ listDateRange, setListDateRange ] = useState<[moment.Moment, moment.Moment]>([ moment(), moment().add(1, 'month')]);
    const [ listSearch, setListSearch ] = useState<string>('');
    const orgData = useContext(OrgDataContext);

    const client = useApolloClient();
    var { data: viewData } = useQuery(gql`
    query GetCalendarView {
        schedulingCalendarView @client
    }
    `)

    const [ dateRange, setDateRange ] = usePersistentDateRange();

    const view = (viewData && viewData.schedulingCalendarView) || '0'

    function setView(value: string){
        client.writeData({
            data: {
                schedulingCalendarView: value
            }
        })
    }

    if (view === '1'){
        var startDate = formatDate(calValue.clone().startOf('month'));
        var endDate = formatDate(calValue.clone().endOf('month'));
    }
    else
    {
        startDate = formatDate(dateRange[0]);
        endDate = formatDate(dateRange[1]);
    }

    const { loading, error, data: queryData } = useQuery(Queries.GetScheduledPaxCgoGroups, {
        fetchPolicy: 'network-only',
        pollInterval: 5000,
        variables: {
            tpID: props.orgData.getOrgIDByType('transporter'),
            customerID: props.orgData.getOrgIDByType('customer'),
            startDate,
            endDate
        }

    })

    let data: Array<ScheduledGroup> = castArray(queryData?.getScheduledPaxInbCgoGroups)
        .map((entity) => ({...entity, scheduledVersion: "V1"}));
    data = data.concat(
        (queryData?.GetScheduledPaxCgoGroupsV2 || [])
            .map((entity) => ({...entity, scheduledVersion: "V2"}))
    );

    data.sort((a, b) => {
        return a.scheduledFlightDate > b.scheduledFlightDate ? 1 : -1;
    })

    if (view === '0' && listSearch.length > 0){
       data = data.filter((item) => item.scheduledGroup.toLowerCase().includes(listSearch.toLowerCase().trim()))
    }

    let content: React.ReactElement;

    let viewActions: React.ReactElement;

    switch (view) {
        case '0':
            viewActions = <Row type="flex" gutter={12}>
                <Col style={{ maxWidth: '16rem' }}>
                    <RangePicker
                        value={dateRange}
                        onChange={(dates: [moment.Moment, moment.Moment]) => setDateRange(dates) }
                        format="MMM DD, YY"
                        disabledDate={(current) => moment().isAfter(current, 'month')}
                    />
                </Col>
                <Col style={{ flex: 1 }}>
                    <Input
                        placeholder="Search schedules"
                        prefix={<Icon type="search" style={{ color: 'rgba(0,0,0,.25)' }} />}
                        value={listSearch}
                        onChange={(e) => setListSearch(e.target.value)}
                        allowClear
                    />
                </Col>
            </Row>
            content = <div style={{ padding: '0 1rem' }}>
                <GroupList data={data} onGroupClicked={(group) => props.onGroupClicked && props.onGroupClicked(group)} />
            </div>
            break;
        case '1':
        default:
            viewActions = <Row type="flex" gutter={12}>
                <Col>
                    <Button block onClick={() => setCalValue(moment())}>This Month</Button>
                </Col>
                <Col style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                    <MonthSelect
                        style={{ width: '100%' }}
                        value={calValue}
                        onChange={(value) => setCalValue(value)}
                    />
                </Col>
            </Row>
            content = <ScheduledGroupsCalendar
                data={data}
                value={calValue}
                onGroupClicked={(group) => props.onGroupClicked && props.onGroupClicked(group)}
            />
            break;
    }

    let newScheduleUrl = `/app/scheduling/passenger/quickschedule`;
    if (!orgData.organization?.useV1Scheduler){
        newScheduleUrl = `/app/scheduling/schedule/new`;
    }

    return <MainLayout>
        <MainHeader
            routes={[
                {
                    path: '/app/scheduling/groups',
                    breadcrumbName: 'Scheduled Passengers and Cargo'
                }
            ]}
            actions={
                <Row type="flex" gutter={12} align="middle">
                    <Col>
                        <Radio.Group
                            buttonStyle="solid"
                            onChange={(e) => setView(e.target.value)}
                            value={view}
                        >
                            <Radio.Button value="0"><Icon type="unordered-list" style={{ marginRight: 6 }} />List</Radio.Button>
                            <Radio.Button value="1"><Icon type="calendar" style={{ marginRight: 6 }} />Calendar</Radio.Button>
                        </Radio.Group>
                    </Col>
                    <Divider type="vertical" style={dividerStyle} />
                    <Col style={{ flex: 1, width: '28rem' }}>
                        {viewActions}
                    </Col>
                    <UserGroup.Flytsuite.Schedule.Passenger>
                        <Divider type="vertical" style={dividerStyle} />
                        <Col>
                            <ButtonLink to={newScheduleUrl} type="primary">New Schedule</ButtonLink>
                        </Col>
                    </UserGroup.Flytsuite.Schedule.Passenger>
                </Row>
            }
        />
        <Content>
            {error ? <Alert
                message="Failed to load scheduled groups"
                description={error.message}
                type="error"
                showIcon
                banner
            /> : null}
            <div className="ant-spin-fill-height">
                <Spin spinning={loading} size="large" indicator={<Icon type="loading" />}>
                    {content}
                </Spin>
            </div>
        </Content>
    </MainLayout>
}

export default WithOrgData(withUserGroups( ScheduledGroupsList))