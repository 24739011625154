import {useEffect, useState} from "react";
import {LocationSelect} from "components/form/select/LocationSelect";
import {Button, Tooltip} from "antd";
import './style.less';
import {SelectProps} from "antd/lib/select";
import {ValidationRule} from "antd/lib/form";

export interface DepDestSelectValue {
    departure: { key: string, label: string } | null,
    destination: { key: string, label: string } | null
}

export interface DepDestSelectProps {
    value?: DepDestSelectValue,
    onChange?: (value: DepDestSelectValue) => void,
    showSwap?: boolean,
    size?: 'small' | 'large' | 'default',
    departureSelectProps?: SelectProps,
    destinationSelectProps?: SelectProps
}

type DepDestSelectType = React.FC<DepDestSelectProps> & { validator: typeof validate}

const DEFAULT_VALUE = {
    departure: null,
    destination: null
}

const BASE_CLS = 'dep-dest-select';

const DepDestSelect: DepDestSelectType = (props) => {

    const [ state, _setState ] = useState<DepDestSelectValue>(DEFAULT_VALUE);

    const controlledMode = props.value !== undefined;

    // If controlledMode is enabled, state is controlled by props.value.
    useEffect(() => {
        if (controlledMode){
            _setState(props.value);
        }
    }, [
        props.value?.departure,
        props.value?.destination
    ]);

    function handleChange(fieldName: keyof DepDestSelectValue, value: { key: string, label: string }){
        _setState(currState => {
            const newState = { ...currState, [fieldName]: value }
            props.onChange?.(newState);

            return newState;
        });
    }

    function clear(){
        _setState(DEFAULT_VALUE);
    }

    function swap(){
        _setState(currState => ({
            departure: currState.destination,
            destination: currState.departure
        }))
    }

    return (
        <div className={BASE_CLS + '-wrapper'}>
            <LocationSelect
                size={props.size}
                placeholder="Departure"
                {...props.departureSelectProps}
                value={state.departure}
                onChange={(value) => handleChange('departure', value)}
                labelInValue
            />
            <LocationSelect
                size={props.size}
                placeholder="Destination"
                {...props.destinationSelectProps}
                value={state.destination}
                onChange={(value) => handleChange('destination', value)}
                labelInValue
            />
            <Tooltip title="Swap departure and destination">
                <Button
                    icon="retweet"
                    size={props.size}
                    onClick={swap}
                    disabled={!state.departure && !state.destination}
                />
            </Tooltip>
        </div>
    )

}

DepDestSelect.defaultProps = {
    showSwap: true
}

function validate(rules: ValidationRule, value: any, callback: (msg?: React.ReactNode) => void){
    console.log('validating');

    // Dep and dest are filled out
    const isComplete = !!(value?.departure && value?.destination)

    if (!isComplete && rules.required){
        callback(rules.message || 'Please choose a departure and destination.');
        return;
    }

    // All is good
    callback();
}

DepDestSelect.validator = validate;

export default DepDestSelect;