import gql from "graphql-tag";

export default gql`
query LocationQuery(
    $filter: LocationsFilterInput
    $limit: Int = 15
){
    locations(
        filter: $filter
        limit: $limit
    ){
        docs {
            _id
            ... on Location {
                name
                block
                field
                areaBlockName
                deletedTs
            }
        }
    }
}
`