import gql from "graphql-tag";

export default gql`
    query GetPerson($id: ID!){
        person(id: $id){
            _id
            lastName
            firstName
            customerID {
                _id
                name
            }
            employerID {
                _id
                name
            }
            emergencyContactInfo {
                address {
                    city
                    state
                    street
                    zipCode
                }
                email {
                    type
                    address
                }
                name {
                    firstName
                    lastName
                }
                phone {
                    number
                    type
                }
            }
            dob
            contactInfo {
                address {
                    city
                    state
                    street
                    zipCode
                }
                email {
                    type
                    address
                }
                name {
                    firstName
                    lastName
                }
                phone {
                    number
                    type
                }
            }
        }
        personCredentials(personId: $id){
            _id
            key
            expiration
            type
        }
    }
`