import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import CurrentOrg from '../../CurrentOrg';
import PrivateRoute from '../../PrivateRoute';
import FlightScheduleEdit from 'components/flights/scheduling/FlightScheduleEdit';
import MainScheduling from './MainScheduling';
import SetsList from '../../scheduling/SetsList';
import moment from 'moment';
import { Spin, Icon, message, Alert } from 'antd';
import SetsEditorContainer from '../../../containers/SetsEditorContainer';
import PaxCgoScheduler from 'components/scheduling/PaxCgoScheduler';
import ScheduledGroupsList from 'components/scheduling/ScheduledGroupsList';
import ScheduledFlightsList from 'components/scheduling/ScheduledFlightsList';
import Phase2Scheduler from "../../../pages/app/scheduling/phase-2-scheduler";
import ButtonLink from "../../ButtonLink";

export const basePath = (path) => `/app/scheduling${path ? `/${path}` : ''}`

/** Connects PaxCgoScheduler state with route url data */
const PaxSchedulerWrapper = ({
    match,
    routes,
    editing,
    paxEdit,
    onEditingChange,
    onCancel,
    onSaveSuccessful,
    onNewSetClicked,
    onDeleteGroupSuccessful,
    onBackToList,
    onToScheduledFlights
}) => {

    const { params } = match;
    var group;
    if (params && params.date && params.scheduledGroup && params.departureID && params.destinationID && params.lastKnownController) {
        group = {
            date: params.date,
            name: decodeURIComponent(params.scheduledGroup),
            departureID: decodeURIComponent(params.departureID),
            destinationID: decodeURIComponent(params.destinationID),
            lastKnownController: decodeURIComponent(params.lastKnownController)
        }
    }
    const getGroupDataWithOrg = (orgData) => {
        if (!group){
            return group;
        }
        if (!orgData){
            return group;
        }
        const { customer, transporter } = orgData;
        return {
            ...group,
            tpID: transporter && transporter._id,
            customerID: customer && customer._id
        }
    }
    return (
        <CurrentOrg>
        {(orgData) => (
            <PaxCgoScheduler
                groupData={getGroupDataWithOrg(orgData)}
                mainHeaderRoutes={routes}
                onSaveSuccessful={onSaveSuccessful}
                editing={editing}
                paxEdit={paxEdit}
                onEditingChange={onEditingChange}
                onCancel={onCancel}
                onNewSetClicked={onNewSetClicked}
                onDeleteGroupSuccessful={onDeleteGroupSuccessful}
                onBackToList={onBackToList}
                onToScheduledFlights={onToScheduledFlights}
            />
        )}
        </CurrentOrg>
    )
}

/** Connects PaxCgoScheduler state with route url data */
const Phase2SchedulerWrapper = (
    {
         match,
         routes,
         editing,
         onEditingChange,
         onCancel,
         onSaveSuccessful,
         onNewSetClicked,
         onDeleteGroupSuccessful,
         onBackToList,
         onToScheduledFlights
    }) => {

    const { params } = match;
    var group;
    if (params && params.date && params.scheduledGroup && params.lastKnownController) {
        group = {
            date: params.date,
            name: decodeURIComponent(params.scheduledGroup),
            lastKnownController: decodeURIComponent(params.lastKnownController)
        }
    }
    const getGroupDataWithOrg = (orgData) => {
        if (!group){
            return group;
        }
        if (!orgData){
            return group;
        }
        const { customer, transporter } = orgData;
        return {
            ...group,
            tpID: transporter && transporter._id,
            customerID: customer && customer._id
        }
    }
    return (
        <CurrentOrg>
            {(orgData) => (
                <Phase2Scheduler
                    groupData={getGroupDataWithOrg(orgData)}
                    mainHeaderRoutes={routes}
                    onScheduleSaved={onSaveSuccessful}
                    editMode={editing}
                    onEditModeChange={onEditingChange}
                    onCancel={onCancel}
                    onNewSetCreated={onNewSetClicked}
                    onScheduleDeleted={onDeleteGroupSuccessful}
                    onFinished={onBackToList}
                    onToScheduledFlights={onToScheduledFlights}
                />
            )}
        </CurrentOrg>
    )
}

/** Connects SetsEditor state with route url data */
const renderSetsEditor = ({
    match: { params, path },
    history
}) => {
    let isNew = path === basePath('sets/new');
    let isScheduling = path === basePath('sets/:id/schedule');
    let isEditing = path === basePath('sets/:id/edit') || isNew || isScheduling;
    
    return (
        <SetsEditorContainer
            setId={params.id}
            isEditing={isEditing}
            isScheduling={isScheduling}
            isNew={isNew}
            onSaved={() => history.push(basePath('sets'))}
            onDeleted={() => history.push(basePath('sets'))}
            onScheduled={(data) => {
                const date      = encodeURIComponent(data.date);
                const group     = encodeURIComponent(data.group);
                const lkc       = encodeURIComponent(data.lastKnownController);
                const dep       = encodeURIComponent(data.departureID);
                const dest      = encodeURIComponent(data.destinationID);
                history.push(
                    basePath(`groups/${date}/${group}/${lkc}/${dep}/${dest}`)
                )
            }}

            // Build breadcrumb for header
            createRoutes={({ loading, setName }) => {
                const renderName = () => {
                    return loading ? <Spin indicator={<Icon type="loading" spin />} /> : setName
                }
                let routes = [
                    {
                        path: '/app/scheduling/sets',
                        title: 'Passenger Sets'
                    }
                ]
                if (params.id){
                    routes.push({
                        path: `/app/scheduling/sets/${params.id}`,
                        title: renderName()
                    })
                }
                if (isNew){
                    routes.push({
                        title: 'New'
                    })
                }
                else if (isScheduling) {
                    routes.push({
                        title: 'Schedule'
                    })
                }
                else if (isEditing) {
                    routes.push({
                        title: 'Edit'
                    })
                }
                return routes;
            }}
            onEditChange={(val) => {
                if (val){
                    history.push(basePath(`sets/${params.id}/edit`))
                }
                else if (!isNew)
                {
                    history.push(basePath(`sets/${params.id}`))
                }
                else {
                    history.push(basePath(`sets`))
                }
            }}
            onScheduleChange={(val) => {
                if (val){
                    history.push(basePath(`sets/${params.id}/schedule`))
                }
                else
                {
                    history.push(basePath(`sets/${params.id}`))
                }
            }}
        />
    )
}

const phase2SchedulingRoutesLegacy = [
    <Redirect exact from={basePath('passenger/quickschedule')} to={basePath('groups/quickschedule')} />,
    <PrivateRoute
        exact
        path={basePath('groups/quickschedule')}
        render={(props) => (
            <PaxSchedulerWrapper
                {...props}
                editing={true}
                paxEdit={false}
                onCancel={() => props.history.push(basePath('groups'))}
                onSaveSuccessful={({ variables: v }) => {
                    if (!v) return;
                    let flightDate      = encodeURIComponent( moment(v.scheduledFlightDate).format('YYYY-MM-DD') );
                    let groupName       = encodeURIComponent( v.scheduledGroup );
                    let lkc             = encodeURIComponent( v.lastKnownController );
                    let departureID     = encodeURIComponent( v.departureID );
                    let destinationID   = encodeURIComponent( v.destinationID );

                    var path = basePath(`groups/${flightDate}/${groupName}/${lkc}/${departureID}/${destinationID}`);
                    props.history.push(path);
                }}

                // Set up breadcrumb on header
                routes={[
                    {
                        path: '/app/scheduling/groups',
                        title: 'Scheduled Passengers and Cargo'
                    },
                    {
                            title: 'New Schedule'
                    }
                ]}
            />
        )}
    />,
    <Redirect
        exact
        from={basePath('passenger/:date/:scheduledGroup/:lastKnownController/:departureID/:destinationID')}
        to={basePath('groups/:date/:scheduledGroup/:lastKnownController/:departureID/:destinationID')}
    />,
    <PrivateRoute
        exact
        path={basePath('groups/:date/:scheduledGroup/:lastKnownController/:departureID/:destinationID')}
        render={(props) => (
            <PaxSchedulerWrapper
                {...props}
                editing={false}
                paxEdit={true}
                onCancel={() => props.history.push(basePath('groups'))}
                onSaveSuccessful={() => {
                    props.history.push(basePath('groups'))
                }}
                onDeleteGroupSuccessful={() => {
                    props.history.push(basePath('groups'))
                }}
                onEditingChange={(editing) => {
                    if (editing === false){
                        return;
                    }
                    var { date, scheduledGroup, departureID, destinationID, lastKnownController } = props.match.params;
                    scheduledGroup = encodeURIComponent(scheduledGroup);
                    departureID = encodeURIComponent(departureID);
                    destinationID = encodeURIComponent(destinationID);
                    lastKnownController = encodeURIComponent(lastKnownController);
                    var path = basePath(`groups/${date}/${scheduledGroup}/${lastKnownController}/${departureID}/${destinationID}/edit`)
                    props.history.push(path)
                }}
                onNewSetClicked={(id) => props.history.push(basePath(`sets/${id}`))}
                onBackToList={() => {
                    props.history.push(basePath('groups'))
                }}
                onToScheduledFlights={() => {
                    props.history.push(basePath('flight'))
                }}

                // Set up breadcrumb on header
                routes={[
                    {
                        path: '/app/scheduling/groups',
                        title: 'Scheduled Passengers and Cargo'
                    },
                    {
                        title: decodeURIComponent(props.match.params.scheduledGroup)
                    }
                ]}
            />
        )}
    />,
    <Redirect
        exact
        from={basePath('passenger/:date/:scheduledGroup/:lastKnownController/:departureID/:destinationID/edit')}
        to={basePath('groups/:date/:scheduledGroup/:lastKnownController/:departureID/:destinationID/edit')}
    />,
    <PrivateRoute
        exact
        path={basePath('groups/:date/:scheduledGroup/:lastKnownController/:departureID/:destinationID/edit')}
        render={(props) => {
            const { date, scheduledGroup, departureID, destinationID, lastKnownController } = props.match.params;
            var path = basePath(`groups/${date}/${scheduledGroup}/${lastKnownController}/${departureID}/${destinationID}`);
            return (
                <PaxSchedulerWrapper
                    {...props}
                    editing={true}
                    paxEdit={false}
                    onCancel={() => {
                        props.history.push(path)
                    }}
                    onSaveSuccessful={({ data, variables: v }) => {
                        if (data && (data.paxnodes || data.cgonodes)){
                            if (!v) return;
                            var path = basePath(`groups/${encodeURIComponent(moment(v.scheduledFlightDate).format('YYYY-MM-DD'))}/${encodeURIComponent(v.scheduledGroup)}/${encodeURIComponent(v.lastKnownController)}/${encodeURIComponent(v.departureID)}/${encodeURIComponent(v.destinationID)}`)
                            props.history.push(path);
                        }
                        else
                        {
                            props.history.push(basePath('groups'))
                        }
                    }}
                    onDeleteGroupSuccessful={() => {
                        props.history.push(basePath('groups'))
                    }}
                    onEditingChange={(editing) => {
                        if (editing === true){
                            return;
                        }
                        props.history.push(path)
                    }}
                    routes={[
                        {
                            path: '/app/scheduling/groups',
                            title: 'Scheduled Passengers and Cargo'
                        },
                        {
                            title: decodeURIComponent(props.match.params.scheduledGroup),
                            path: path
                        },
                        {
                            title: 'Edit'
                        }
                    ]}
                />
            )
        }}
    />
]

const phase2SchedulingRoutes_V2 = [
    <PrivateRoute
        exact
        path={basePath('schedule/new')}
        render={(props) => (
            <Phase2SchedulerWrapper
                {...props}
                editing={true}
                paxEdit={false}
                onCancel={() => props.history.push(basePath('groups'))}
                onSaveSuccessful={({ variables: v }) => {
                    if (!v) return;
                    let flightDate      = encodeURIComponent( moment(v.scheduledFlightDate).format('YYYY-MM-DD') );
                    let groupName       = encodeURIComponent( v.scheduledGroup );
                    let lkc             = encodeURIComponent( v.lastKnownController );

                    var path = basePath(`schedule/${flightDate}/${groupName}/${lkc}`);
                    props.history.push(path);
                }}

                // Set up breadcrumb on header
                routes={[
                    {
                        path: '/app/scheduling/schedule',
                        title: 'Scheduled Passengers and Cargo'
                    },
                    {
                        title: 'New Schedule'
                    }
                ]}
            />
        )}
    />,
    <PrivateRoute
        exact
        path={basePath('schedule/:date/:scheduledGroup/:lastKnownController')}
        render={(props) => (
            <Phase2SchedulerWrapper
                {...props}
                editing={false}
                paxEdit={true}
                onCancel={() => props.history.push(basePath('schedule'))}
                onSaveSuccessful={() => {
                    props.history.push(basePath('groups'))
                }}
                onDeleteGroupSuccessful={() => {
                    props.history.push(basePath('schedule'))
                }}
                onEditingChange={(editing) => {
                    if (editing === false){
                        return;
                    }
                    var { date, scheduledGroup, departureID, destinationID, lastKnownController } = props.match.params;
                    scheduledGroup = encodeURIComponent(scheduledGroup);
                    departureID = encodeURIComponent(departureID);
                    destinationID = encodeURIComponent(destinationID);
                    lastKnownController = encodeURIComponent(lastKnownController);
                    var path = basePath(`schedule/${date}/${scheduledGroup}/${lastKnownController}/edit`)
                    props.history.push(path)
                }}
                onNewSetClicked={(id) => props.history.push(basePath(`sets/${id}`))}
                onBackToList={() => {
                    props.history.push(basePath('groups'))
                }}
                onToScheduledFlights={() => {
                    props.history.push(basePath('flight'))
                }}

                // Set up breadcrumb on header
                routes={[
                    {
                        path: '/app/scheduling/groups',
                        title: 'Scheduled Passengers and Cargo'
                    },
                    {
                        title: decodeURIComponent(props.match.params.scheduledGroup)
                    }
                ]}
            />
        )}
    />,
    <PrivateRoute
        exact
        path={basePath('schedule/:date/:scheduledGroup/:lastKnownController/edit')}
        render={(props) => {
            const { date, scheduledGroup, departureID, destinationID, lastKnownController } = props.match.params;
            var path = basePath(`schedule/${date}/${scheduledGroup}/${lastKnownController}`);
            return (
                <Phase2SchedulerWrapper
                    {...props}
                    editing={true}
                    onCancel={() => {
                        props.history.push(path)
                    }}
                    onSaveSuccessful={({ modifiedEntities, variables: v }) => {
                        if (modifiedEntities?.length){
                            if (!v) return;
                            let flightDate = encodeURIComponent( moment(v.scheduledFlightDate).format('YYYY-MM-DD') );
                            let groupName = encodeURIComponent( v.scheduledGroup );
                            let lastKnownController = encodeURIComponent( v.lastKnownController );
                            var path = basePath(`schedule/${flightDate}/${groupName}/${lastKnownController}`);
                            props.history.push(path);
                        }
                        else
                        {
                            props.history.push(basePath('groups'))
                        }
                    }}
                    onDeleteGroupSuccessful={() => {
                        props.history.push(basePath('groups'))
                    }}
                    onEditingChange={(editing) => {
                        if (editing === true){
                            return;
                        }
                        props.history.push(path)
                    }}
                    routes={[
                        {
                            path: '/app/scheduling/groups',
                            title: 'Scheduled Passengers and Cargo'
                        },
                        {
                            title: decodeURIComponent(props.match.params.scheduledGroup),
                            path: path
                        },
                        {
                            title: 'Edit'
                        }
                    ]}
                />
            )
        }}
    />
]

/** Handles all routes related to scheduling */
// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
    <Switch>
        <PrivateRoute exact path={basePath('sets')} component={SetsList} />
        <PrivateRoute exact path={basePath('sets/new')} render={renderSetsEditor} />
        <PrivateRoute exact path={basePath('sets/:id')} render={renderSetsEditor} />
        <PrivateRoute exact path={basePath('sets/:id/edit')} render={renderSetsEditor} />
        <PrivateRoute exact path={basePath('sets/:id/schedule')} component={renderSetsEditor} />
        <Redirect exact from={basePath('passenger')} to={basePath('groups')} />
        <PrivateRoute
            exact
            path={basePath('groups')}
            render={({ history }) => {
                return <ScheduledGroupsList
                    onGroupClicked={({ date, name, lkc, departureID: dep, destinationID: dest }) => {
                        let flightDate = encodeURIComponent( moment(date).format('YYYY-MM-DD') );
                        let groupName = encodeURIComponent( name );
                        let lastKnownController = encodeURIComponent( lkc );
                        let depID = dep ? encodeURIComponent( dep ) : null;
                        let destID = dest ? encodeURIComponent( dest ) : null;

                        if (!depID && !destID)
                            // This must be a scheduler V2 group
                            history.push(basePath(`schedule/${flightDate}/${groupName}/${lastKnownController}`))
                        else
                            history.push(basePath(`groups/${flightDate}/${groupName}/${lastKnownController}/${depID}/${destID}`));
                        }
                    }
                />
            }}
        />
        {phase2SchedulingRoutesLegacy}
        {phase2SchedulingRoutes_V2}
        <PrivateRoute
            exact
            path={basePath('flight')}
            render={({ history }) => {
                return <ScheduledFlightsList
                    onFlightClicked={(flight) => history.push(basePath(`flight/${flight._id}`))}
                    onNewFlightSubmitted={(flightId) => history.push(basePath(`flight/${flightId}`))}
                />
            }}
        />
        <PrivateRoute exact path={basePath('flight/:id')} component={FlightScheduleEdit} />
        <PrivateRoute component={MainScheduling} />
    </Switch>
)