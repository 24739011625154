import ApolloClient from "apollo-client";
import {ResolveLocationIdsQuery} from "Queries/Location";
import {Location} from "common/types/location";
import moment from "moment";
import {OrgDataProviderContext} from "../../../../context/orgData";
import {DepDestListItem, GroupData, GroupFormData, ScheduleVariables} from "./types";
import {createFormField, FormField} from "../../../../common/form";
import {momentOrNull} from "../../../../common/util";
import {v4 as uuidv4} from 'uuid';
import {ScheduledCgoNode, ScheduledPaxNode, ScheduleNode} from "../../../../components/scheduling/types";

export const BASE_CLS = 'phase-2-scheduler';

export async function deRefLocations(apolloClient:ApolloClient<any>, locationIds: string[]){
    const { data, errors } = await apolloClient.query({
        query: ResolveLocationIdsQuery,
        errorPolicy: 'all',
        fetchPolicy: 'cache-first',
        variables: {
            ids: locationIds
        }
    })

    return {
        data: (data?.resolve_entity_ids || []) as Location[],
        errors: errors || []
    }
}

export function preparePassengerForSchedule(pax: ScheduledPaxNode){
    return {
        _id: pax._id,
        _rev: pax._rev,
        personID: pax.personID._id,
        transitType: pax.transitType,
        paxWeight: pax.paxWeight,
        bagWeight: pax.bagWeight,
        bagCount: pax.bagCount,
        chargeCode: pax.chargeCode && pax.chargeCode.toUpperCase(),
        departureID: pax.departureID?._id,
        destinationID: pax.destinationID?._id,
        scheduledOrder: pax.scheduledOrder,
        notesForPilot: pax.notesForPilot
    }
}

export function prepareCargoForSchedule(cgo: ScheduledCgoNode){
    return {
        _id: cgo._id,
        _rev: cgo._rev,
        name: cgo.name,
        weight: cgo.weight,
        hazmat: cgo.hazmat,
        hazmatUnCode: cgo.hazmatUnCode,
        attentionTo: cgo.attentionTo,
        notes: cgo.notes,
        receivedLocationID: cgo.receivedLocationID,
        scheduledOrder: cgo.scheduledOrder,
        departureID: cgo.departureID?._id,
        destinationID: cgo.destinationID?._id,
        initials: cgo.initials,
        transitType: cgo.transitType,
        notesForPilot: cgo.notesForPilot
    }
}

export type PreparedScheduledPax = ReturnType<typeof preparePassengerForSchedule>;
export type PreparedScheduledCgo = ReturnType<typeof prepareCargoForSchedule>;

export function createSaveMutationVars(orgData: OrgDataProviderContext, groupFieldsValues: {
    lastKnownController: string,
    group: string,
    date: any
}, data: ScheduleNode[], deletedData: ScheduleNode[], defaultChargeCode?: string): ScheduleVariables {
    function applyDefChgCode(obj: ScheduledPaxNode){
        return {
            ...obj,
            chargeCode: (obj.chargeCode && obj.chargeCode.toUpperCase()) || (defaultChargeCode && defaultChargeCode.toUpperCase())
        }
    }
    return {
        customerID: orgData.getOrgIDByType('customer'),
        tpID: orgData.getOrgIDByType('transporter'),
        lastKnownController: groupFieldsValues.lastKnownController,
        scheduledGroup: groupFieldsValues.group,
        scheduledFlightDate: moment(groupFieldsValues.date).format('YYYY-MM-DD'),
        modifiedPax: data.filter(item => item.classType === 'flytsuite.paxnode')
            .map(applyDefChgCode)
            .map(preparePassengerForSchedule),
        modifiedCgo: data.filter(item => item.classType === 'flytsuite.cgonode')
            // .map((cgo: ScheduledCgoNode) => ({
            //     ...cgo,
            //     receivedLocationID: cgo.transitType === TransitType.INBOUND ? cgo.departureID?._id : undefined
            // }))
            .map(prepareCargoForSchedule),
        deletedEntities: deletedData && deletedData
            .filter(item => !!item._rev)
            .map(item => ({ _id: item._id, _rev: item._rev, classType: item.classType }))
    }
}

export function sortByScheduledOrder(a: ScheduleNode, b: ScheduleNode){
    if (b.scheduledOrder === null) return -1;
    if (a.scheduledOrder === null) return 1;

    const diff = (a.scheduledOrder || 0) - (b.scheduledOrder || 0);
    if (diff === 0){
        // scheduledOrders are equal. Put paxnodes before cgonodes.
        return a.classType === 'flytsuite.paxnode' && b.classType === 'flytsuite.cgonode' ? -1 : 1;
    }
    return diff;
}

export function updateScheduledOrder(entity: ScheduleNode, index: number){
    return {
        ...entity,
        scheduledOrder: index
    }
}

export class DepDestList {
    static build(scheduleNodes: ScheduleNode[]){

        // Ordered map
        const depDestList = new Map<string, DepDestListItem>();

        // Used for building key for depDestList
        function hashLeg(departure: Location, destination: Location){
            return departure?._id + ':' + destination?._id
        }

        // Create a copy b/c sort sorts in-place.
        const scheduleNodesCopy = [...scheduleNodes];
        // First, sort by scheduled order:
        scheduleNodesCopy.sort(sortByScheduledOrder);

        let order = 0;
        for (const node of scheduleNodesCopy) {
            const hash = hashLeg(node.departureID, node.destinationID);
            if (!depDestList.has(hash)){
                // Create new entry with this node in it
                depDestList.set(hash, {
                    departure: node.departureID,
                    destination: node.destinationID,
                    rangeStart: order,
                    rangeEnd: order,
                    pax: [node].filter(e => e.classType === 'flytsuite.paxnode') as ScheduledPaxNode[],
                    cgo: [node].filter(e => e.classType === 'flytsuite.cgonode') as ScheduledCgoNode[],
                    all: [node]
                })
            }
            else
            {
                const leg = depDestList.get(hash);

                // Will be updated if a pax/cgo has a greater scheduledOrder.
                let nextRangeEnd = leg.rangeEnd;

                // Insert node into existing list
                if (node.classType === 'flytsuite.paxnode'){
                    leg.pax.push(node);
                    nextRangeEnd = Math.max(nextRangeEnd, order);
                }
                else if (node.classType === 'flytsuite.cgonode'){
                    leg.cgo.push(node);
                    nextRangeEnd = Math.max(nextRangeEnd,order);
                }
                leg.all.push(node);

                // Update rangeEnd
                leg.rangeEnd = nextRangeEnd;
            }

            order++;
        }

        return Array.from(depDestList.values());
    }

    static toEntities(legs: DepDestListItem[]): ScheduleNode[] {

        const entityList: ScheduleNode[] = [];

        let order = 0;
        for (let leg of legs) {
            for (let entity of leg.all) {
                entityList.push({
                    ...entity,
                    scheduledOrder: order
                })
                order++;
            }
        }

        return entityList;
    }
}


export function findLegIdx(list: DepDestListItem[], leg: DepDestListItem){
    return list.findIndex(item => (
        item.departure?._id === leg.departure?._id ||
        item.destination?._id === leg.destination?._id
    ))
}

export function initFormData(groupData: GroupData): GroupFormData {
    return {
        name: createFormField(groupData.name),
        date: createFormField(momentOrNull(groupData.date)),
        lastKnownController: createFormField(groupData.lastKnownControllerObj ? ({
            key: groupData.lastKnownControllerObj._id,
            label: groupData.lastKnownControllerObj.name
        }) : null)
    }
}

export function getFormDataAsDeps(formData: { [key: string]: FormField }){
    const deps = [];

    for (let key in formData) {
        const value = formData[key]?.value;
        deps.push(value);
    }

    return deps;
}

export function formTouched(formData: { [key: string]: FormField }){
    for (let key in formData) {
        if (formData[key]?.touched){
            return true
        }
    }
    return false;
}

export function allRequiredFieldsHaveValue(formData: { [key: string]: FormField }, requiredFieldNames: string[]){
    const touchedFieldNames: string[] = [];

    for (let key in formData) {
        if (formData[key]?.value){
            touchedFieldNames.push(key);
        }
    }

    for (let requiredFieldName of requiredFieldNames) {
        if (!touchedFieldNames.includes(requiredFieldName)){
            return false;
        }
    }

    return true;
}

export function genTempID(){
    return 'TEMP-' + uuidv4()
}

export function getNoteMap(entities: ScheduleNode[]){
    /*  Gather all notesForPilot values from pax and cargo and create a dictionary of them with this structure:
        {
            <MESSAGE>: ScheduleNode[]
        }
     */

    const noteMap: Record<string, ScheduleNode[]> = {};

    for (let entity of entities) {
        const msg = entity.notesForPilot;
        if (!msg) continue;

        if (msg in noteMap){
            noteMap[msg].push(entity);
        }
        else
        {
            noteMap[msg] = [entity];
        }
    }

    return noteMap;
}