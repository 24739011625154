import React from 'react';
import { Row, Col, Divider, Form, Breadcrumb, Layout } from 'antd';
import { Link } from 'react-router-dom';
import { Route } from 'antd/lib/breadcrumb/Breadcrumb';
import './MainHeader.less';

const { Header } = Layout;

// TODO: Remove the title property. Keeping it for now so things don't break.
export type RouteWithTitle = Route & { title?: string }

export interface MainHeaderProps {
    routes?: RouteWithTitle[],
    afterRoutes?: React.ReactNode,
    actions?: JSX.Element,
    formitems?: JSX.Element
}

class MainHeader extends React.Component<MainHeaderProps> {
    renderBreadcrumb = (route: RouteWithTitle) => {
        const { routes } = this.props;
        const last = routes.indexOf(route) === routes.length - 1;
        return last ? <span>{route.title || route.breadcrumbName}</span> : <Link to={route.path}>{route.title}</Link>;
    }
    render(){
        const { routes, actions, formitems } = this.props;
        return (
            <Header className="scheduling-main-header">
                <Row type="flex" style={{alignItems: 'center', flex: 1}}>
                    <Col>
                        <Breadcrumb style={{fontSize: '1.1rem'}} routes={routes} itemRender={this.renderBreadcrumb} />
                    </Col>
                    {this.props.afterRoutes ? (
                        <Col>
                            {this.props.afterRoutes}
                        </Col>
                    ) : null}
                    <Col style={{flex: 1}}>
                        <Row type="flex" justify="end" style={{alignItems: 'center'}}>
                            {
                                formitems && (
                                    <Form layout="inline">
                                        {formitems}
                                    </Form>
                                )
                            }
                            {actions && formitems && <Divider className="scheduling-main-header-divider" type="vertical" />}
                            {actions}
                        </Row>
                    </Col>
                </Row>
            </Header>
        )
    }
}

export default MainHeader