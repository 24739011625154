import gql from "graphql-tag";

const GetNonPreferredQuery = gql`
  query NonPreferred($_id: ID!) {
    getNonPreferredList(_id: $_id) {
      _id
      personIds {
        id {
          _id
          lastName
          firstName
          employerID {
            _id
            name
          }
          contactInfo {
            address {
              city
              state
            }
          }
        }
        startDate
        endDate
        reason
        modifiedBy
      }
      organizationIds {
        id {
          _id
          name
          classType
        }
        startDate
        endDate
        reason
        modifiedBy
      }
    }
  }
`;

export default GetNonPreferredQuery;
