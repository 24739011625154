import React, {useContext, useRef} from "react";
import * as Phase2State from './state';
import {Alert, Button, Col, Divider, Dropdown, Icon, Input, Layout, Menu, message, Modal, Row, Tooltip} from "antd";
import {BASE_CLS, deRefLocations} from "./util";
import SelectionCount from "../../../../components/search/SelectionCount";
import GroupForm from "./form";
import {TransitType} from "../../../../common/types/carriable";
import './phase-2-scheduler.less';
import Phase2Table from "./table";
import useUserGroups from "../../../../hooks/useUserGroups";
import NewLegForm from "./new-leg-form";
import {useApolloClient} from "react-apollo";
import {Location} from "common/types/location";
import {NewLegFormData} from "./types";
import * as NotesForPilot from "../../../../components/flights/scheduling/notes-for-pilot";
import {getSNodeID} from "../../../../components/scheduling/util";

const { Content } = Layout;

export interface Phase2EditorProps {
    groupFormRef: React.MutableRefObject<any>
}

const Phase2Editor: React.FC<Phase2EditorProps> = (props) => {

    // Context API -----------------------------------------------------------------------------------------------------
    const { api } = useContext(Phase2State.Context);

    const editMode = api.editMode.get();

    const data = api.data;
    const saveStatus = api.saveStatus;
    const hideCarrierPanel = api.carriableSelector.hideCarriablePanel;
    const chargeCodes = api.chargeCodes;
    const selectedEntityIds = api.selection.entityIds;
    // -----------------------------------------------------------------------------------------------------------------

    // Hooks -----------------------------------------------------------------------------------------------------------
    const groupFormRef = React.useRef(null);
    const newLegModalFormRef = React.useRef(null);
    const [ userGroups ] = useUserGroups();
    const apolloClient = useApolloClient();
    const popupContainerRef = useRef<HTMLDivElement>(null);
    // -----------------------------------------------------------------------------------------------------------------

    // State helpers ---------------------------------------------------------------------------------------------------
    const hasData = !!api.data.modifiedEntities.get().length;
    const scheduledPax = api.data.modifiedEntities.get().filter(c => c.classType === 'flytsuite.paxnode');
    // -----------------------------------------------------------------------------------------------------------------

    function runIsnCheck() {
        if (api.editMode.get()){
            message.error('Cannot run ISN check in edit mode');
            return;
        }
        api.bulkIsnChecker.runChecks(
            scheduledPax
                .filter(p => p.transitType === TransitType.OUTBOUND)
                .map((pax) => getSNodeID(pax))
        );
    }

    // Toolbar ---------------------------------------------------------------------------------------------------------
    let collapserIcon = 'menu-fold';
    let collapserTooltipText = 'Hide personnel search';
    if (hideCarrierPanel) {
        collapserIcon = 'menu-unfold';
        collapserTooltipText = 'Show personnel search';
    }

    function renderMoveToMenu(){

        const firstID = selectedEntityIds.get()[0];
        const firstSelectedEntity = api.data.modifiedEntities.get().find(e => getSNodeID(e) === firstID);

        return <Menu.SubMenu
            title="Move to..."
            disabled={!api.selection.entityIds.get().length}
        >
            <Menu.Item
                onClick={() => {

                    const callback = (departure: Location, destination: Location) => {
                        api.data.setEntityLegs(
                            api.selection.entityIds.get(),
                            departure,
                            destination
                        )
                    }

                    // Automatically fill in the departure and destination from
                    // the first selected entity.
                    if (firstSelectedEntity) {
                        api.modal.newLegFormModal.open(
                            firstSelectedEntity.departureID,
                            firstSelectedEntity.destinationID,
                            callback
                        );
                    }
                    else {
                        api.modal.newLegFormModal.open();
                    }
                }}
            >
                <span>New...</span>
                <span style={{ float: 'right' }}>
                    <Icon type="plus" />
                </span>
            </Menu.Item>
            <Divider type="horizontal" style={{ margin: 0 }} />
            {api.data.getLegs().map((leg) => {
                return (
                    <Menu.Item
                        key={`${leg.departure._id}::${leg.destination._id}`}
                        onClick={() => api.data.setEntityLegs(
                            api.selection.entityIds.get(),
                            leg.departure,
                            leg.destination)
                        }
                    >
                        {leg.departure.name + ' → ' + leg.destination.name}
                    </Menu.Item>
                )
            })}
        </Menu.SubMenu>
    }

    function renderSelectLegMenu(){
        return <Menu.SubMenu
            key="selectleg"
            title="Select leg..."
        >
            {api.data.getLegs().map((leg) => {
                return (
                    <Menu.Item
                        key={`${leg.departure._id}::${leg.destination._id}`}
                        onClick={() => api.selection.entityIds.add(leg.all.map(e => getSNodeID(e)))}
                    >
                        {leg.departure.name + ' → ' + leg.destination.name}
                    </Menu.Item>
                )
            })}
        </Menu.SubMenu>
    }

    const toolbar = (
        <Row type="flex" align="middle" gutter={8} className={BASE_CLS + '-toolbar'}>
            {editMode ? (
                <>
                    <Col>
                        <Button icon={collapserIcon} onClick={hideCarrierPanel.toggle} title={collapserTooltipText} />
                    </Col>
                    <Divider type="vertical" />
                    <Col>
                        <Dropdown
                            trigger={['click']}
                            overlay={
                                <Menu /*onClick={handleAction}*/>
                                    <Menu.Item
                                        disabled={!selectedEntityIds.get().length}
                                        key="remove"
                                        onClick={() => api.data.removeEntities(selectedEntityIds.get())}
                                    >Remove</Menu.Item>
                                    {renderMoveToMenu()}
                                    <Menu.Item
                                        key="pilotNoteEditor"
                                        onClick={api.notesForPilot.editor.open}
                                    >
                                        Edit Pilot Notes
                                    </Menu.Item>
                                    <Menu.Item
                                        disabled={scheduledPax.findIndex((pax) => pax.chargeCode) === -1}
                                        key="clearChargeCodes"
                                        onClick={chargeCodes.clear}
                                    >Clear Charge Codes</Menu.Item>
                                    <Menu.Item
                                        title="Resets all modifications to pax/cgo"
                                        onClick={() => {
                                            Modal.confirm({
                                                title: 'Are you sure you want to undo all changes?',
                                                okText: 'Undo Everything',
                                                okButtonProps: {
                                                    type: 'danger'
                                                },
                                                onOk: () => {
                                                    api.data.reset();
                                                }
                                            })
                                        }}
                                    >
                                        Undo all changes
                                    </Menu.Item>
                                </Menu>
                            }
                        >
                            <Button>
                                Edit <Icon type="down" />
                            </Button>
                        </Dropdown>
                    </Col>
                </>
            ) : null}
            {editMode ? (
                <>
                    <Col>
                        <Dropdown
                            trigger={['click']}
                            overlay={
                                <Menu /*onClick={handleAction}*/>
                                    <Menu.Item
                                        disabled={!hasData || selectedEntityIds.get().length >= api.data.modifiedEntities.get().length}
                                        key="selectall"
                                        onClick={selectedEntityIds.all}
                                    >Select All</Menu.Item>
                                    <Menu.Item
                                        disabled={!hasData || !selectedEntityIds.get().length}
                                        key="deselectall"
                                        onClick={selectedEntityIds.clear}
                                    >De-select All</Menu.Item>
                                    {renderSelectLegMenu()}
                                </Menu>
                            }
                        >
                            <Button>
                                Select <Icon type="down" />
                            </Button>
                        </Dropdown>
                    </Col>
                </>
            ) : null}
            <Col>
                <Dropdown
                    trigger={['click']}
                    overlay={
                        <Menu /*onClick={handleAction}*/>
                            <Menu.Item
                                key="selectall"
                                onClick={api.editor.visibleLegs.showAll}
                            >Expand All Tables</Menu.Item>
                            <Menu.Item
                                key="deselectall"
                                onClick={api.editor.visibleLegs.hideAll}
                            >Collapse All Tables</Menu.Item>
                        </Menu>
                    }
                >
                    <Button>
                        View <Icon type="down" />
                    </Button>
                </Dropdown>
            </Col>
            {editMode ? (
                <Col>
                    <Button
                        onClick={api.notesForPilot.editor.open}
                    >
                        Pilot Note Editor
                    </Button>
                </Col>
            ) : null}
            {editMode ? (
                <Col style={{ marginLeft: 'auto' }}>
                    <span>Default Charge Code: </span>
                    <Input
                        style={{ width: 'auto', marginLeft: '0.25rem' }}
                        onChange={e => chargeCodes.setDefault(e.target.value.split(" ").join(""))}
                        value={chargeCodes.getDefault()}
                    />
                </Col>
            ) : null}
            {!editMode ? (
                userGroups.includes('flytsuite.schedule.passenger') ? (
                    <Button
                        disabled={api.bulkIsnChecker.running}
                        type="primary"
                        onClick={api.editMode.toggle}
                    >
                        <Icon type="edit" style={{ marginRight: 6 }} />Edit
                    </Button>
                ) : (
                    <Tooltip title="You don't have permission to edit schedules">
                        <Button disabled><Icon type="edit" style={{ marginRight: 6 }} />Edit</Button>
                    </Tooltip>
                )
            ) : null}
            <div style={{ flex: 1 }} />
            {selectedEntityIds.get().length ? (
                <>
                    <Col>
                        <SelectionCount onClear={selectedEntityIds.clear} items={selectedEntityIds.get()} />
                    </Col>
                </>
            ) : null}
        </Row>
    )
    // -----------------------------------------------------------------------------------------------------------------


    // Form ------------------------------------------------------------------------------------------------------------
    const form = <GroupForm
        wrappedComponentRef={(ref) => {
            groupFormRef.current = ref;
            if (props.groupFormRef){
                props.groupFormRef.current = ref;
            }
        }}
        disabled={!editMode}
        formData={api.forms.groupForm.get()}
        onFieldsChange={api.forms.groupForm.setFields}
    />
    // -----------------------------------------------------------------------------------------------------------------

    // Table header ----------------------------------------------------------------------------------------------------
    const tableHeader = (
        <Row type="flex" className={BASE_CLS + '-table-header'}>
            <Col style={{ flex: 1 }}>
                {form}
            </Col>
            {groupFormRef.current && groupFormRef.current.props.form.isFieldsTouched() ? (
                <Col style={{ paddingTop: '0.2rem', paddingRight: '0.2rem' }}>
                    <Tooltip title="Reset fields" placement="topLeft">
                        <Button icon="undo" onClick={api.forms.groupForm.resetFields} />
                    </Tooltip>
                </Col>
            ) : null}
        </Row>
    )
    // -----------------------------------------------------------------------------------------------------------------

    // Leg tables ------------------------------------------------------------------------------------------------------
    // -----------------------------------------------------------------------------------------------------------------

    // Save status alert -----------------------------------------------------------------------------------------------
    let saveStatusAlert = null;
    if (saveStatus.get()){
        let content = null;
        if (saveStatus.get().status === 'success'){
            content = <>
                <Button
                    type="primary"
                    size="large"
                    onClick={api.schedule.finish}
                >Finish</Button>
                <Divider type="vertical" />
                <Button
                    onClick={() => {
                        runIsnCheck();
                    }}
                    type="link"
                    size="large"
                >Run ISN Check</Button>
            </>
        }
        const desc = (
            <>
                {saveStatus.get().description ? <p>{saveStatus.get().description}</p> : null}
                {content}
            </>
        )

        saveStatusAlert = (
            <Alert
                type={saveStatus.get().status === 'success' ? 'success' : 'error'}
                showIcon
                message={saveStatus.get().message}
                description={desc}
                closable
                onClose={api.saveStatus.dismiss}
            />
        )
    }
    // -----------------------------------------------------------------------------------------------------------------

    return <Content
            className={BASE_CLS + '-content'}
        >
        <div
            ref={popupContainerRef}
        >
            {toolbar}
            {saveStatusAlert}
            {tableHeader}
            <Phase2Table
                containerElement={popupContainerRef?.current}
            />
            <Modal
                title="Move to New Leg"
                visible={api.modal.newLegFormModal.get().visible}
                onCancel={api.modal.newLegFormModal.close}
                okText='Move'
                onOk={() => {
                    newLegModalFormRef.current?.validateFieldsAndScroll(async (err, values: NewLegFormData) => {
                        if (err) return;

                        const departureID = values.depDest.departure?.key;
                        const destinationID = values.depDest.destination?.key;

                        // Convert the location IDs to real locations
                        const result = await deRefLocations(apolloClient, [departureID, destinationID]);
                        if (result.errors.length){
                            message.error('Failed to get locations');
                            return;
                        }
                        const [ departure, destination ] = result.data;

                        // Call the callback and close the modal (which clears the callback too)
                        api.modal.newLegFormModal.callback?.(departure, destination);
                        api.modal.newLegFormModal.close();
                    })
                }}
            >
                <NewLegForm
                    formData={api.forms.newLegForm.get()}
                    onFieldsChange={api.forms.newLegForm.setFields}
                    ref={newLegModalFormRef}
                />
            </Modal>
            <Modal
                title="Edit Notes For Pilot"
                visible={api.notesForPilot.editor.isOpen()}
                onCancel={api.notesForPilot.editor.close}
                bodyStyle={{ padding: 0 }}
                width={700}
                onOk={api.notesForPilot.editor.submit}
                okText="Apply"
            >
                <div
                    className={BASE_CLS + '-toolbar'}
                    style={{
                        marginBottom: '12'
                    }}
                >
                    <NotesForPilot.Editor.Toolbar
                        {...api.notesForPilot.editor.getToolbarProps()}
                    />
                </div>
                <Layout style={{
                    padding: 12,
                    maxHeight: 500,
                    height: 500,
                    overflow: 'auto'
                }}>
                    <NotesForPilot.Editor.Editor
                        {...api.notesForPilot.editor.getEditorProps()}
                        entities={api.data.modifiedEntities.getNotOnCarrier()}
                    />
                </Layout>
            </Modal>
        </div>
    </Content>
}

export default Phase2Editor