import React, {useContext, useReducer} from "react";
import {useMutation, useQuery} from "react-apollo";
import {OrgDataContext} from "../../../../context/orgData";
import {Mutations, MutationTypes, Queries, QueryTypes} from "./graphql";
import * as State from './state';
import {Helper, SearchEvent, SearchEventTypes} from './state';
import {ApiInterface} from "./api";
import ScheduledPaxCgoAdder, {ScheduledPaxCgoAdderProps} from "./scheduled-paxcgo-adder";
import {ScheduledGroup, ScheduledPaxCgoGroupV2Leg} from "../../../../common/types/schedule";
import {PassengerEditor} from "../../../PassengerEditor";
import {Alert, Button, Drawer, Input, message, Modal, Select} from "antd";
import {PaxISNWithData} from "../../../isn/PaxISN";
import {cloneDeep} from "lodash";
import {CgoNode, PaxNode} from "../../../../common/types/carriable";
import {
    buildLocNameMapFromPaxCgoLists,
    getFlightLegManagerFromFlightQuery,
    graphqlCgoToFlightManCgo,
    graphqlPaxToFlightManPax
} from "../../../../common/flight-leg/util";
import {ButtonProps} from "antd/lib/button";
import {SearchProps} from "antd/lib/input";
import {SelectProps} from "antd/lib/select";
import {AlertProps} from "antd/lib/alert";
import {cleanGraphQLErrorMsg} from "../../../../common/util";

type RenderFunc<Props> = (props?: Partial<Props>) => React.ReactElement<Props>

type ReturnType = {
    api: ApiInterface,

    /**
     * Enhances the ScheduledPaxCgoAdder component to consume the ApiInterface.
     * Usage: spread the componentProps object into the props of the ScheduledPaxCgoAdder component.
     * Example:
     *      <ScheduledPaxCgoAdder {...hookVariable.componentProps} />
     */
    componentProps: ScheduledPaxCgoAdderProps,

    /**
     * Renders child components for the UI.
     * These components are optional and not necessary for the hook to work.
     *
     * TODO:    This is probably an anti-pattern now that I look at it. Change each of these into components that we can
     *          spread props into similar to "componentProps"
     */
    render: {
        /**
         * Displays passenger editor popup
         */
        paxModal: () => React.ReactElement,

        /**
         * Displays passenger ISN panel
         */
        isnDrawer: () => React.ReactElement,

        /**
         * Button that allows user to add selected passengers/cargo to the flight.
         */
        addButton: RenderFunc<ButtonProps>,

        /**
         * Search query used to filter results
         */
        searchInput: RenderFunc<SearchProps>,

        /**
         * Allows user to change the fields used to compare against their search query.
         */
        searchTypeSelect: RenderFunc<SelectProps>,

        /**
         * Shows an alert saying filters are applied with the option to clear them.
         */
        filtersAppliedAlert: RenderFunc<AlertProps>,

        /**
         * Displays and error if the data fetch failed
         */
        errorAlert: RenderFunc<AlertProps>,
    }
}

interface Options {

    /**
     * Callback when the flight gets updated successfully
     */
    onFlightUpdated?: () => void
}

/**
 * Build a map of an entity ID to its object for future reference.
 * @param groups
 */
function buildEntityIDMap(groups: ScheduledGroup[]){
    const map = new Map<string, PaxNode | CgoNode>();

    function add(entity: PaxNode | CgoNode){
        map.set(entity._id, entity);
    }

    for (let group of groups) {
        for (let leg of group.legs) {
            leg.paxnodeDocs.forEach(add);
            leg.cgonodeDocs.forEach(add);
        }
    }

    return map;
}

/**
 * React hook that allows child components to consume the ApiInterface as defined in api.ts.
 */
export function useScheduledPaxCgoAdderState(flightId: string, flightDate: string, options?: Options): ReturnType{

    const [ state, dispatch ] = useReducer(State.Reducer, State.DEFAULT_STATE);
    const orgData = useContext(OrgDataContext);

    const queryResult = useQuery<
        QueryTypes['GetScheduledGroups']['Data'],
        QueryTypes['GetScheduledGroups']['Vars']
    >(Queries.GetScheduledGroups, {
        variables: {
            flightid: flightId,
            tpID: orgData.getOrgIDByType('transporter'),
            customerID: orgData.getOrgIDByType('customer'),
            scheduledFlightDate: flightDate
        },
        skip: !flightId || !flightDate,
        pollInterval: 10000,
        fetchPolicy: 'network-only'
    });

    const flight = queryResult.data?.flight;
    const groups = queryResult.data?.groups || [];

    const entityIdMap = buildEntityIDMap(groups);

    function getClassType(id: string){
        return entityIdMap.get(id)?.classType;
    }

    const [ addPaxToFlightMutation, { loading: saving } ] = useMutation<
        MutationTypes['AddPaxToFlight']['Data'],
        MutationTypes['AddPaxToFlight']['Vars']
    >(Mutations.AddPaxToFlight);

    /**
     * Adds pax/cgo to a flight.
     * @param paxnodeIDs
     * @param cgonodeIDs
     */
    function handleAddEntities(paxnodeIDs: string[], cgonodeIDs: string[]){
        const paxnodes = paxnodeIDs.map((id) => entityIdMap.get(id))
            .filter(e => e) as PaxNode[];
        const cgonodes = cgonodeIDs.map((id) => entityIdMap.get(id))
            .filter(e => e) as CgoNode[];

        let flManager = getFlightLegManagerFromFlightQuery(flight, buildLocNameMapFromPaxCgoLists(paxnodes, cgonodes));
        if (paxnodes?.length > 0){
            paxnodes.forEach((pax) => {
                flManager.addPassenger(graphqlPaxToFlightManPax(pax))
            })
        }
        if (cgonodes?.length > 0){
            cgonodes.forEach((cgo) => {
                flManager.addCgo(graphqlCgoToFlightManCgo(cgo))
            })
        }

        var departureID = flManager.getOrigin();
        if (!departureID){
            message.error("Cannot add a group with no departure on a flight.")
            return
        }
        var newLegs = flManager.buildFlightLegs();

        const resultLegs = Object.assign({}, newLegs);
        addPaxToFlightMutation({
            variables: {
                flight: {
                    _id: flight._id,
                    customerID: flight.customerID._id,
                    departureID: departureID,
                    tpID: orgData.transporter._id,
                    paxIDList: flManager.getPaxIDList(),
                    cgoIDList: flManager.getCgoIDList(),
                    legs: JSON.stringify(resultLegs)
                },
                paxIDs: paxnodeIDs,
                cgoIDs: cgonodeIDs,
                tpID: orgData.getOrgIDByType('transporter')
            }
        })
            .then(() => {
                options?.onFlightUpdated?.();
                dispatch({ category: 'SELECTION', type: 'ENTITY', action: 'CLEAR' });
                queryResult.refetch();
            })
            .catch((err) => {
                message.error("Failed to add PAX/CGO to flight");
                console.error('Failed to add entities to flight', err);
            })
    }

    // Consumes groups list and filters according to user filters.
    function getGroups() {
        // Apply filters if necessary
        let userFilter = state.search[state.activeSearch]?.split(/[,| ]/) ?? [];

        // Filter out empty strings
        userFilter = userFilter.filter(query => query);

        function match(str: string){
            if (!userFilter.length) return true;
            return userFilter.every(word => str?.includes(word))
        }

        const entityFilters = [
            function filterEntityByName(entity: PaxNode | CgoNode){
                if (!userFilter || state.activeSearch !== 'NAME') return true;

                let name = entity.name as string;

                if (!name){
                    // Might be a PaxNode. Get the last and first name.
                    name = `${entity.lastName} ${entity.firstName}`;
                }

                name = name.trim().toLowerCase();

                return match(name)
            },
            function filterEntityByEmployer(entity: PaxNode | CgoNode){
                if (!userFilter || state.activeSearch !== 'EMPLOYER') return true;

                const name = String(entity.employerID?.name)
                    .trim()
                    .toLowerCase();

                return match(name)
            }
        ]

        // List of filters that can be applied to legs
        const legFilters = [
            function filterLegByAnything(leg: ScheduledPaxCgoGroupV2Leg) {
                if (!userFilter|| state.activeSearch !== 'LOCATION') return true;

                return (
                    match(String(leg.departureDoc?.name).toLowerCase()) ||
                    match(String(leg.destinationDoc?.name).toLowerCase())
                )
            }
        ]

        function transformLeg(leg: ScheduledPaxCgoGroupV2Leg){
            const legCpy = cloneDeep(leg) as ScheduledPaxCgoGroupV2Leg;
            legCpy.cgonodeIDs = [];
            legCpy.cgonodeDocs = [];
            legCpy.paxnodeIDs = [];
            legCpy.paxnodeDocs = [];

            function addEntity(entity: PaxNode | CgoNode){
                if (!entity) return;

                const ctMap = {
                    'flytsuite.paxnode': 'paxnode',
                    'flytsuite.cgonode': 'cgonode'
                }

                const type = ctMap[entity.classType];
                if (!type) return;

                legCpy[type + 'Docs'].push(entity);
                legCpy[type + 'IDs'].push(entity._id);
            }

            const entityList = [...leg.paxnodeDocs, ...leg.cgonodeDocs];

            for (let entity of entityList) {
                let shouldAdd = true;
                for (let entityFilter of entityFilters) {
                    if (!entityFilter(entity)){
                        shouldAdd = false;
                        break;
                    }
                }
                if (shouldAdd){
                    addEntity(entity);
                }
            }

            return legCpy;
        }

        function transformGroup(group: ScheduledGroup){
            const groupCpy = cloneDeep(group);
            groupCpy.legs = [];

            for (let leg of group.legs) {
                for (let legFilter of legFilters) {
                    if (legFilter(leg)){
                        const legCpy = transformLeg(leg);
                        if (legCpy.paxnodeIDs.length || legCpy.cgonodeIDs.length){
                            groupCpy.legs.push(legCpy);
                        }
                        else
                        {
                            break;
                        }
                    }
                }
            }
            return groupCpy;
        }

        return groups.map(transformGroup)

            // Filter out groups with no legs
            .filter(group => group.legs.length)

    }

    // Implement ApiInterface
    const api: ApiInterface = {
        data: {
            loading: queryResult.loading,
            error: queryResult.error,
            refetch: queryResult.refetch,
            flight: {
                get: () => flight
            },
            groups: {
                get: getGroups,
            }
        },
        selection: (type) => ({
            get: () => {
                switch (type){
                    case "ENTITY":
                        return state.selectedEntities;
                }
            },
            set: (keys) => (
                dispatch({ category: 'SELECTION', type: type, action: 'SET', payload: { keys } })
            ),
            add: (keys) => (
                dispatch({ category: 'SELECTION', type: type, action: 'ADD', payload: { keys } })
            ),
            remove: (keys) => (
                dispatch({ category: 'SELECTION', type: type, action: 'REMOVE', payload: { keys } })
            ),
            clear: () => {
                dispatch({ category: 'SELECTION', type: type, action: 'CLEAR' })
            },
            toggle: (keys) => {
                dispatch({ category: 'SELECTION', type: type, action: 'TOGGLE', payload: { keys } })
            }
        }),

        /**
         * Actions that a user wants to happen such as clicking a button to add pax to a flight.
         */
        action: {
            flight: {
                addEntities: handleAddEntities,
                addSelectedEntities: () => {
                    const paxNodeIds = state.selectedEntities
                        .filter(entityId => getClassType(entityId) === 'flytsuite.paxnode');

                    const cgoNodeIds = state.selectedEntities
                        .filter(entityId => getClassType(entityId) === 'flytsuite.cgonode');

                    handleAddEntities(paxNodeIds, cgoNodeIds);
                },

                // True while the flight is being updated
                saving: saving
            },
            groupCollapseKeys: {
                get: () => state.openGroupPanels,
                set: (keys) => (
                    dispatch({
                        category: 'ACTION',
                        type: 'COLLAPSE.GROUPS',
                        action: 'SET',
                        payload: { panelKeys: keys }
                    })
                )
            },
            legCollapseKeys: {
                get: (group) => state.openLegPanels[Helper.panelKey.stringify(group)],
                set: (group, keys) => (
                    dispatch({
                        category: 'ACTION',
                        type: 'COLLAPSE.LEGS',
                        action: 'SET',
                        payload: {
                            group: group,
                            panelKeys: keys
                        }
                    })
                )
            },
            collapse: new class {
                expandAll = () => {
                    const groupKeys = groups.map(Helper.panelKey.stringify);
                    dispatch({
                        category: 'ACTION',
                        type: 'COLLAPSE.GROUPS',
                        action: 'SET',
                        payload: { panelKeys: groupKeys }
                    });

                    for (let group of groups) {
                        const legKeys = group.legs.map(Helper.legKey.stringify);
                        dispatch({
                            category: 'ACTION',
                            type: 'COLLAPSE.LEGS',
                            action: 'SET',
                            payload: {
                                group: group,
                                panelKeys: legKeys
                            }
                        })
                    }
                };

                collapseAll = () => {
                    dispatch({
                        category: 'ACTION',
                        type: 'COLLAPSE.GROUPS',
                        action: 'SET',
                        payload: { panelKeys: [] }
                    });
                    for (let group of groups) {
                        dispatch({
                            category: 'ACTION',
                            type: 'COLLAPSE.LEGS',
                            action: 'SET',
                            payload: {
                                group: group,
                                panelKeys: []
                            }
                        })
                    }
                };

                toggleAll = () => {
                    if (this.isAllExpanded())
                        this.collapseAll();
                    else
                        this.expandAll()
                };

                isAllExpanded = () => {
                    return state.openGroupPanels.length >= groups.length
                }
            },
            paxModal: {
                open: (paxData) => dispatch({
                    category: 'ACTION',
                    type: 'MODAL.PAX',
                    action: 'OPEN',
                    payload: {
                        paxData: paxData
                    }
                }),
                close: () => dispatch({
                    category: 'ACTION',
                    type: 'MODAL.PAX',
                    action: 'CLOSE'
                }),
                isOpen: state.paxModal.open,
                paxData: state.paxModal.paxData
            },
            isnDrawer: {
                open: (paxData) => dispatch({
                    category: 'ACTION',
                    type: 'DRAWER.ISN',
                    action: 'OPEN',
                    payload: {
                        paxData: paxData
                    }
                }),
                close: () => dispatch({
                    category: 'ACTION',
                    type: 'DRAWER.ISN',
                    action: 'CLOSE'
                }),
                isOpen: state.isnDrawer.open,
                paxData: state.isnDrawer.paxData
            }
        },
        search: {
            get: (type) => ({
                get: () => {
                    return state.search[type] || ''
                },
                set: (text) => (
                    dispatch({ category: 'SEARCH', type: type, action: 'SET', payload: { text } })
                )
            }),
            clearAll: () => (
                dispatch({ category: 'ACTION', type: 'SEARCH', action: 'CLEAR' })
            ),
            filterApplied: () => (
                !!Object.values(state.search)
                    .filter(v => v)
                    .length
            )
        },
        activeSearch: {
            get: () => state.activeSearch,
            getName: () => SearchEventTypes[state.activeSearch],
            set: (type: SearchEvent['type']) => (
                dispatch({ category: 'ACTION', type: 'ACTIVE.SEARCH', action: 'SET', payload: { activeSearch: type } })
            ),
            list: () => Object.entries(SearchEventTypes)
                .map(([ type, name ]) => (
                    {
                        type: type as keyof typeof SearchEventTypes,
                        name
                    }
                ))
        }
    }

    const componentProps = {
        data: api.data,
        selectionEvent: api.selection,
        collapseGroupKeys: api.action.groupCollapseKeys,
        collapseLegKeys: api.action.legCollapseKeys,
        onAddEntitiesToFlight: api.action.flight.addEntities,
        onPaxNameClicked: (paxData) => (
            api.action.paxModal.open(paxData)
        ),
        onIsnButtonClicked: (paxData) => (
            api.action.isnDrawer.open(paxData)
        ),
        isAddingToFlight: saving
    }

    return {
        api: api,
        componentProps: componentProps,
        render: {
            paxModal: () => (
                <Modal
                    title="Edit Passenger"
                    visible={api.action.paxModal.isOpen}
                    onCancel={api.action.paxModal.close}
                    footer={null}
                    width="50rem"
                    >
                    <PassengerEditor
                        title="Edit Passenger"
                        edit={true}
                        data={api.action.paxModal.paxData}
                        onCancel={api.action.paxModal.close}
                    />
                </Modal>
            ),
            isnDrawer: () => {
                let pax = api.action.isnDrawer.paxData;
                let title = pax ? `${pax.lastName}, ${pax.firstName}` : '';
                return (
                    <Drawer
                        title={title}
                        visible={api.action.isnDrawer.isOpen}
                        width="50rem"
                        onClose={api.action.isnDrawer.close}
                        closable
                    >
                        <PaxISNWithData paxId={pax?._id} />
                    </Drawer>
                )
            },
            addButton: (props) => {
                return (
                    <Button
                        disabled={
                            !api.selection('ENTITY').get().length || saving
                        }
                        type="primary"
                        onClick={api.action.flight.addSelectedEntities}
                        {...props}
                    >
                        Add {api.selection('ENTITY').get().length} Selected PAX/CGO
                    </Button>
                )
            },
            searchInput: (props) => {
                return (
                    <Input.Search
                        onChange={(e) => (
                            api.search.get(
                                api.activeSearch.get()
                            ).set(e.target.value)
                        )}
                        value={api.search.get(
                            api.activeSearch.get()
                        ).get()}
                        placeholder={`Search by ${api.activeSearch.getName().toLowerCase()}`}
                        enterButton
                        allowClear
                        {...props}
                        style={{
                            width: 300,
                            ...props?.style
                        }}
                    />
                )
            },
            searchTypeSelect: (props) => {
                return (
                    <Select
                        value={api.activeSearch.get()}
                        onChange={api.activeSearch.set}
                        {...props}
                    >
                        {api.activeSearch.list().map(({ type, name }) => (
                            <Select.Option key={type} value={type}>By {name}</Select.Option>
                        ))}
                    </Select>
                )
            },
            filtersAppliedAlert: (props) => {
                if (!api.search.filterApplied()) return null;

                return (
                    <Alert
                        showIcon
                        message={
                            <>
                                <span>Results are filtered</span>
                                <Button
                                    type="primary"
                                    size="small"
                                    style={{
                                        marginLeft: 12
                                    }}
                                    onClick={api.search.clearAll}
                                >Clear filter</Button>
                            </>
                        }
                        {...props}
                        style={{
                            marginBottom: 16,
                            ...(props?.style)
                        }}
                    />
                )
            },
            errorAlert: (props) => {
                if (!api.data.error) return null;

                return (
                    <Alert
                        showIcon
                        type="error"
                        message="Failed to load schedules"
                        description={cleanGraphQLErrorMsg(api.data.error)}
                        {...props}
                        style={{ marginBottom: 12, ...(props?.style) }}
                    />
                )
            }
        }
    }
}