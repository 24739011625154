import { Location } from "./location";

// TODO: Implement these

export enum TransitType {
    OUTBOUND='OUTBOUND',
    INBOUND='INBOUND',
    TRANSFER='TRANSFER'
}

export interface Carriable {
    _id: string,
    transitType: TransitType,
    classType: string,
    departureID: Location,
    destinationID: Location
    scheduledOrder: number,
    notesForPilot: string
    [field: string]: any
}
export interface Person extends Carriable {

}
export interface PaxNode extends Person {
    classType: 'flytsuite.paxnode'
}
export interface CgoNode extends Carriable {
    classType: 'flytsuite.cgonode'
}