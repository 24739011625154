import { Alert, Button, Col, Drawer, Icon, Popconfirm, Row, Table } from 'antd';
import { LocationSelect } from 'components/form/select/LocationSelect';
import { DraggableBodyRow } from 'components/TableDragSorting';
import compose from 'lodash.flowright';
import React from 'react';
import WithOrgData from '../../WithOrgData';

class AddLegsToFlightDrawer extends React.Component {
    state = {
        locationsToAdd: [...this.props.legs],
        visible: this.props.visible
    }

    render(){
        if(this.props.visible && this.state.visible !== this.props.visible){
            this.setState({visible: this.props.visible, locationsToAdd: [...this.props.legs]})
        }else if(!this.props.visible && this.state.visible !== this.props.visible){
            this.setState({visible: this.props.visible})
        }
        return (
            <Drawer width="50rem" title={
                <Row>
                    <Col span={12}>Add Legs Manually</Col>
                    <Col span={10}>
                        <Row type="flex" justify="end">
                            Search: <LocationSelect onChange={value => this.setState({locationsToAdd: [...this.state.locationsToAdd, value]})} labelInValue style={{minWidth: '10rem'}}/>
                        </Row>
                    </Col>
                </Row>
            } {...this.props}>
                {this.state.error && <Alert type="error" title="Failed to add legs to flight" description={this.state.error.message} showIcon style={{marginBottom: '1rem'}} />}
                {this.state.locationsToAdd.length > 0 ?(
                    <Row>
                        <Col span={12}>Drag to reorder flight locations</Col>
                        <Popconfirm title="Changing the legs will remove all passengers from the flight. Are you sure?" okText="Save legs and remove passengers" onConfirm={() => {
                            this.props.onLegsReorder(this.state.locationsToAdd)
                            this.props.onClose()
                        }}>
                            <Button type="danger">Save</Button>
                        </Popconfirm>
                        <Col>
                            <Table
                                className="draggable-table"
                                dataSource={this.state.locationsToAdd} 
                                columns={[{
                                    title: 'Delete',
                                    key: 'delete',
                                    render: (location, __, index) => (
                                        <Row type="flex" gutter={6}>
                                            <Col>
                                                <Popconfirm title="Are you sure you want to remove this item?" okText="Remove" onConfirm={() => {
                                                    this.setState({locationsToAdd: this.state.locationsToAdd.filter(loc => loc !== location)})
                                                }}>
                                                    <Button size="small" shape="circle" type="danger"><Icon type="delete" /></Button>
                                                </Popconfirm>
                                            </Col>
                                        </Row>
                                    ),
                                    width: 50
                                },{
                                    title: '#',
                                    key: '#',
                                    render: (_, __, index) => index + 1,
                                    width: 25
                                },{
                                    title: "Name",
                                    key: "name",
                                    dataIndex: "label"
                                }]} 
                                rowKey={(_, index) => index} 
                                size="small" 
                                pagination={false}
                                components={{
                                    body: {
                                        row: DraggableBodyRow
                                    }
                                }}
                                onRow={(row, index) => {
                                    const rowProps = {
                                        moveRow: (dragIndex, hoverIndex) => {
                                            var arr = this.state.locationsToAdd
                                            arr.splice(hoverIndex, 0, arr.splice(dragIndex, 1)[0]);
                                            this.setState({locationsToAdd: arr})
                                        },
                                        onClick: () => {console.log(this.state.locationsToAdd)},
                                        index
                                    }
                                    return { ...rowProps}
                                }}/>
                                </Col>
                            </Row>): null}
            </Drawer> 
        )
    }
}

export default compose(
    WithOrgData
)(AddLegsToFlightDrawer)