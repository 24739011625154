import React from 'react';
import { Row, Col } from 'antd';
import { withUserGroups, UserGroup } from '../../userGroup';
import NotAuthorized from '../../NotAuthorized';
import WorkFlowCard from './WorkflowCard';
import './MainScheduling.less';

const paxSetsDesc = `
It is recommended that if you have reoccurring sets of personnel travelling between specific locations on a 
regular basis that you construct a passenger set which you can 
then use to schedule the persons as passengers quickly by supplying a flight date.
`

const scheduledPaxDesc = `
If you have a non-reoccurring or un-planned person travelling, 
you do not need to create a passenger set, 
but rather you can go directly to Step 2 (Scheduled Passengers and Cargo) 
and schedule the person as a passenger without going 
through the unneeded effort of creating a passenger set.
`

const basePath = '/app/scheduling/';

const MainScheduling = ({ userGroups }) => (
    <UserGroup.Flytsuite.Schedule.Any renderNotAuthorized={<NotAuthorized/>}>
        <h1 style={{ marginBottom: 0 }}>Scheduling Workflow</h1>
        <div style={{ marginBottom: '12px' }}>The scheduling process consists of the following steps:</div>
        <Row type="flex" style={{alignItems: 'stretch'}} gutter={24} className="mc-workflow-row">
            <Col span={8}>
                <WorkFlowCard 
                    number="1" 
                    title="Passenger Sets"
                    description="A reusable set of Inbound and Outbound persons traveling between two specific locations."
                    path={basePath + "sets"}
                    disabled={!userGroups.includes('flytsuite.schedule.sets')}
                    detaileddescription={paxSetsDesc}
                    />
            </Col>
            <Col span={8}>
                <WorkFlowCard 
                    number="2" 
                    title="Scheduled Passengers and Cargo"
                    description={`Assign a flight date to a Passenger Set and convert each person into a scheduled passenger and perform an optional ISN Check for the flight date.
                    Manually schedule personnel and inbound cargo.
                    `}
                    path={basePath + "passenger"}
                    disabled={!userGroups.includes('flytsuite.schedule.passenger') && !userGroups.includes('flytsuite.schedule.passenger.view')}
                    detaileddescription={scheduledPaxDesc}
                    />
            </Col>
            <Col span={8}>
                <WorkFlowCard 
                    number="3" 
                    title="Scheduled Flights"
                    description="Schedule flights from one or more groups of scheduled passengers."
                    path={basePath + "flight"}
                    disabled={!userGroups.includes('flytsuite.schedule.flight')}
                    />
            </Col>
        </Row>
    </UserGroup.Flytsuite.Schedule.Any>
)

export default withUserGroups( MainScheduling )