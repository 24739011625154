
export enum LocationType {
    ONSHORE='ONSHORE',
    OFFSHORE='OFFSHORE'
}

export interface Location {
    _id: string,
    name: string,
    type: LocationType
}