import React from "react";
import {cloneDeep, difference, union, unionBy, uniqBy} from 'lodash';
import {CargoFormFields, GroupData, GroupFormData, NewLegFormFields} from "./types";
import {Phase2Api} from "./api";
import * as Util from './util';
import {Location} from 'common/types/location';
import {createFormField, FormErrors} from "../../../../common/form";
import {getTransitType} from "../../../../common/carriable/util";
import {produce} from "immer";
import * as NotesForPilot from '../../../../components/flights/scheduling/notes-for-pilot';
import {ScheduledPaxNode, ScheduleNode} from "../../../../components/scheduling/types";
import {getSNodeID} from "../../../../components/scheduling/util";

export enum ActionType {
    // Data manipulation
    SET_MODIFIED_ENTITIES='SET_MODIFIED_ENTITIES',
    INIT_DATA='INIT_DATA',
    RESET_DATA='RESET_DATA',

    // Forms
    RESET_FORM_DATA='RESET_FORM_DATA',
    SET_FORM_DATA='SET_FORM_DATA',

    // Query status
    SET_QUERY_STATUS='SET_QUERY_STATUS',

    // Save status
    SET_SAVE_STATUS='SET_SAVE_STATUS',
    DISMISS_SAVE_STATUS='DISMISS_SAVE_STATUS',

    // Passenger selection
    SET_SELECTED_PERSONNEL_IDS='SET_SELECTED_PERSONNEL_IDS',

    // Tab selection
    SET_CARRIABLE_SELECTOR_TAB='SET_CARRIABLE_SELECTOR_TAB',
    SET_EDIT_MODE='SET_EDIT_MODE',

    // CarrierSelector
    SET_CARRIABLE_SEL_SEARCH='SET_CARRIABLE_SEL_SEARCH',
    SET_HIDE_CARRIABLE_PANEL='SET_HIDE_CARRIABLE_PANEL',
    SET_LEG_SELECTOR='SET_LEG_SELECTOR',

    // Editor
    SET_SELECTED_ENTITY_IDS='SET_SELECTED_ENTITY_IDS',
    CLEAR_CHARGE_CODES='CLEAR_CHARGE_CODES',
    SET_DEFAULT_CHARGE_CODE='SET_DEFAULT_CHARGE_CODE',
    SET_ISN_DRAWER='SET_ISN_DRAWER',
    SET_VISIBLE_LEGS='SET_VISIBLE_LEGS',

    // Modals
    SET_PAXNODE_MODAL='SET_PAXNODE_MODAL',
    SET_NEW_LEG_MODAL='SET_NEW_LEG_MODAL',

    // Notes for pilot
    NOTES_FOR_PILOT_POPUP='NOTES_FOR_PILOT_POPUP',
    NOTES_FOR_PILOT_EDITOR='NOTES_FOR_PILOT_EDITOR',
    SUBMIT_NOTES_FOR_PILOT_EDITOR='SUBMIT_NOTES_FOR_PILOT_EDITOR'
}

export const DEFAULT_STATE = {
    editMode: true,
    data: {
        originalEntities: [] as ScheduleNode[],
        modifiedEntities: [] as ScheduleNode[],
        deletedEntities: [] as ScheduleNode[]
    },
    queryStatus: {
        loading: false,
        error: null as any
    },
    saveStatus: null as ({
        status: 'success' | 'error',
        message: string,
        description?: string
    }) | null,

    carriableSelector: {
        selectedPersonnelIds: [] as string[],
        upperTab: 'personSearch' as 'personSearch' | 'cargoForm',
        lowerTab: 'byName' as 'byName' | 'byId',
        legSelector: {
            departure: null as { key: string, label: string },
            destination: null as { key: string, label: string }
        },
        searchForm: {
            lastName: null as string,
            firstName: null as string,
            credId: null as string
        },
        hidePanel: true
    },
    forms: {
        initGroupForm: {
            date: null,
            name: null,
            lastKnownController: null
        } as GroupFormData,
        groupForm: {
            date: null,
            name: null,
            lastKnownController: null
        } as GroupFormData,
        cargoForm: {
            name: null,
            weight: null,
            hazmat: null,
            hazmatUnCode: null,
            attentionTo: null,
            notes: null
        } as CargoFormFields,
        newLegForm: {
            depDest: createFormField({
                departure: null,
                destination: null
            })
        } as NewLegFormFields
    },
    formErrors: {
        cargoFormErrors: {} as FormErrors
    },
    modal: {
        paxModal: {
            pax: null as ScheduledPaxNode,
            visible: false
        },
        newLegFormModal: {
            visible: false,
            callback: null as (departure: Location, destination: Location) => void
        },
        messageModal: {
            visible: false
        }
    },
    editor: {
        selectedEntityIds: [] as string[],
        defaultChargeCode: '' as string,
        isnDrawer: {
            pax: null as ScheduledPaxNode,
            visible: false
        },
        visibleLegs: [] as { departureID: string, destinationID: string }[]
    },

    // Controls the "Notes For Pilot" column inputs
    // Because we are centralizing the state here, it won't be possible to have more than one input popup open at once.
    notesForPilotPopup: {
        entityId: null as string,
        ...NotesForPilot.QuickEdit.DEFAULT_STATE
    },

    notesForPilotEditor: {
        open: false,
        state: NotesForPilot.Editor.DEFAULT_STATE
    }
}

export type State = typeof DEFAULT_STATE;

export type Actions  =
    // Data manipulation
    {
        type: ActionType.INIT_DATA,
        entities: ScheduleNode[],
        group: GroupData
    } |
    {
        type: ActionType.SET_MODIFIED_ENTITIES,
        add?: ScheduleNode[],
        remove?: string[]
        set?: ScheduleNode[]
    } |
    {
        type: ActionType.RESET_DATA
    } |

    // Forms
    {
        type: ActionType.RESET_FORM_DATA,
        cargo?: boolean,
        group?: boolean,
        newLeg?: boolean
    } |
    {
        type: ActionType.SET_FORM_DATA,
        group?: typeof DEFAULT_STATE.forms.groupForm
        cargo?: typeof DEFAULT_STATE.forms.cargoForm,
        newLeg?: typeof DEFAULT_STATE.forms.newLegForm
    } |

    // Query Status
    {
        type: ActionType.SET_QUERY_STATUS,
        payload: typeof DEFAULT_STATE.queryStatus
    } |

    // Save Status
    {
       type: ActionType.SET_SAVE_STATUS,
       payload: typeof DEFAULT_STATE.saveStatus
    } |
    {
        type: ActionType.DISMISS_SAVE_STATUS
    } |

    // Passenger selection
    {
        type: ActionType.SET_SELECTED_PERSONNEL_IDS,
        payload: string[]
    } |

    // Tab selection
    {
        type: ActionType.SET_CARRIABLE_SELECTOR_TAB,
        payload: {
            upperTab: typeof DEFAULT_STATE.carriableSelector.upperTab,
            lowerTab: typeof DEFAULT_STATE.carriableSelector.lowerTab
        }
    } |

    // Edit mode
    {
        type: ActionType.SET_EDIT_MODE,
        payload: boolean
    } |

    // Set CarriableSelector
    {
        type: ActionType.SET_CARRIABLE_SEL_SEARCH,
        payload: typeof DEFAULT_STATE.carriableSelector.searchForm
    } |
    {
        type: ActionType.SET_HIDE_CARRIABLE_PANEL,
        payload: boolean | undefined
    } |
    {
        type: ActionType.SET_LEG_SELECTOR,
        payload: {
            departure: typeof DEFAULT_STATE.carriableSelector.legSelector.departure,
            destination: typeof DEFAULT_STATE.carriableSelector.legSelector.destination,
        }
    } |

    // Editor
    {
        type: ActionType.SET_SELECTED_ENTITY_IDS,
        add?: typeof DEFAULT_STATE.editor.selectedEntityIds,
        remove?: typeof DEFAULT_STATE.editor.selectedEntityIds,
        set?: typeof DEFAULT_STATE.editor.selectedEntityIds
    } |
    {
        type: ActionType.CLEAR_CHARGE_CODES
    } |
    {
        type: ActionType.SET_DEFAULT_CHARGE_CODE,
        payload: string
    } |
    {
        type: ActionType.SET_ISN_DRAWER,
        payload: typeof DEFAULT_STATE.editor.isnDrawer
    } |
    {
        type: ActionType.SET_VISIBLE_LEGS,
        mode: 'objects',
        action: 'set' | 'add',
        payload: typeof DEFAULT_STATE.editor.visibleLegs
    } |
    {
        type: ActionType.SET_VISIBLE_LEGS,
        mode: 'strings',
        action: 'set' | 'add',
        payload: string[],
        splitBy: string
    } |

    // Modals
    {
        type: ActionType.SET_PAXNODE_MODAL,
            visible?: boolean,
            pax?: ScheduledPaxNode
    } |
    {
        type: ActionType.SET_NEW_LEG_MODAL,
        visible: boolean,
        newLegValue?: typeof DEFAULT_STATE.forms.newLegForm.depDest.value,
        callback?: typeof DEFAULT_STATE.modal.newLegFormModal.callback
    } |

    // Notes For Pilot
    {
        type: ActionType.NOTES_FOR_PILOT_POPUP,
        entityId: string,
        action: NotesForPilot.QuickEdit.Action
    } |
    {
        type: ActionType.NOTES_FOR_PILOT_EDITOR,
        open?: boolean,
        action?: NotesForPilot.Editor.Action
    } |
    {
        type: ActionType.SUBMIT_NOTES_FOR_PILOT_EDITOR
    }

export const Reducer: React.Reducer<State, Actions> = (state, action) => {

    class HelperFuncs {
        public static setVisibleLegs(visibleLegs: typeof DEFAULT_STATE.editor.visibleLegs){
            return {
                ...state,
                editor: {
                    ...state.editor,
                    visibleLegs
                }
            }
        }
        public static addVisibleLegs(visibleLegs: typeof DEFAULT_STATE.editor.visibleLegs){
            return this.setVisibleLegs(
                unionBy(
                    visibleLegs,
                    state.editor.visibleLegs,
                    this.depDestToString
                )
            )
        }
        public static depDestToString(item: { departureID: string, destinationID: string }, splitter: string='::'){
            return item.departureID + splitter + item.destinationID ;
        }
        public static depDestFromString(str: string, splitBy: string){
            const separated = String(str).split(splitBy);
            if (separated.length >= 2){
                return {
                    departureID: separated[0],
                    destinationID: separated[1]
                }
            }
        }
        private static _MUTABLE_validateSelectedEntities(state: State){
            // DO NOT USE OUTSIDE HelperFuncs.validateState!

            // Make sure pax/cgo assigned to a flight don't end up selected.
            state.editor.selectedEntityIds = state.editor.selectedEntityIds.filter(id => {
                return !!state.data.modifiedEntities.find(e => getSNodeID(e) === id && !e.currentCarrierID)
            })
        }
        public static validateState(state: State){
            return produce(state, (draft) => {
                this._MUTABLE_validateSelectedEntities(draft);
            })
        }
    }

    const nextState = getNextState();
    return HelperFuncs.validateState(nextState);

    function getNextState(){
        switch (action.type){
            // Data manipulation
            case ActionType.INIT_DATA:
                const entities = [...action.entities];
                entities.sort(Util.sortByScheduledOrder);

                let groupForm = DEFAULT_STATE.forms.groupForm;
                if (action.group){
                    groupForm = Util.initFormData(action.group);
                }

                return {
                    ...state,
                    data: {
                        // Deep copy initial entity list to ensure original stat is preserved
                        originalEntities: cloneDeep(entities),

                        modifiedEntities: entities,
                        deletedEntities: []
                    },
                    forms: {
                        ...state.forms,
                        initGroupForm: groupForm,
                        groupForm: groupForm
                    }
                }

            case ActionType.RESET_DATA:
                // Reset data back to their original
                return {
                    ...state,
                    data: {
                        ...state.data,
                        modifiedEntities: state.data.originalEntities,
                        deletedEntities: []
                    }
                }

            // Forms
            case ActionType.RESET_FORM_DATA: {
                let newState = {...state};

                const resetData = (
                    reset: boolean,
                    formName: keyof typeof DEFAULT_STATE.forms) => {

                    if (!reset) return;

                    newState = {
                        ...newState,
                        forms: {
                            ...newState.forms,
                            [formName]: DEFAULT_STATE[formName]
                        }
                    }
                }

                resetData(action.group, 'groupForm');
                resetData(action.cargo, 'cargoForm');
                resetData(action.newLeg, 'newLegForm');

                return newState;
            }
            case ActionType.SET_FORM_DATA: {
                let newState = {...state};

                if (action.cargo) {
                    let initials = action.cargo.initials?.value;
                    if (initials) {
                        initials = String(initials).toUpperCase();
                    }

                    if (action.cargo.initials?.value) {
                        action.cargo.initials.value = initials;
                    } else {
                        action.cargo.initials = createFormField(initials);
                    }

                    newState = {
                        ...newState,
                        forms: {
                            ...newState.forms,
                            cargoForm: action.cargo
                        }
                    }
                }

                if (action.group) {
                    newState = {
                        ...newState,
                        forms: {
                            ...newState.forms,
                            groupForm: action.group
                        }
                    }
                }

                if (action.newLeg) {
                    newState = {
                        ...newState,
                        forms: {
                            ...newState.forms,
                            newLegForm: action.newLeg
                        }
                    }
                }

                return newState;
            }

            case ActionType.SET_MODIFIED_ENTITIES: {
                let newState = {...state};

                if (action.set) {
                    newState = {
                        ...newState,
                        data: {
                            ...newState.data,
                            modifiedEntities: action.set
                                .map(Util.updateScheduledOrder)
                        }
                    }
                }

                if (action.remove) {
                    newState = {
                        ...newState,
                        data: {
                            ...newState.data,
                            modifiedEntities: newState.data.modifiedEntities.filter(e => !action.remove.includes(getSNodeID(e)))
                                .map(Util.updateScheduledOrder),
                            deletedEntities: unionBy(
                                // Make sure we aren't adding 'new' entities to the deleted list.
                                // New entities being ones added by the user that weren't in the list originally.
                                newState.data.originalEntities.filter(e => action.remove.includes(getSNodeID(e))),

                                newState.data.deletedEntities,
                                e => getSNodeID(e)
                            )
                        },
                        editor: {
                            ...newState.editor,
                            // Clear deleted items from the selection list
                            selectedEntityIds: difference(newState.editor.selectedEntityIds, action.remove)
                        }
                    }
                }

                if (action.add) {

                    let newEntities: ScheduleNode[] = unionBy(newState.data.modifiedEntities, action.add, (e) => getSNodeID(e));

                    if (newEntities.length === newState.data.modifiedEntities.length) {

                        // Workaround for editing existing entities weight values not working.
                        newEntities.forEach((e: ScheduleNode, i: number) => {
                            action.add.forEach(actionE => {
                                if (getSNodeID(e) === getSNodeID(actionE)) {
                                    newEntities[i] = actionE;
                                }
                            })
                        })

                        // No new entities were added. Preserve their scheduledOrder.
                        // Needed to do this because "editing" a passenger uses this, and it kept changing their order.
                        newEntities.sort(Util.sortByScheduledOrder);
                    } else {
                        // Use to sort pax and cgo into groups
                        const legs = Util.DepDestList.build(newEntities);

                        let sorted = [];
                        for (let leg of legs) {
                            sorted = sorted.concat(leg.pax, leg.cgo);
                        }

                        newEntities = sorted.map(Util.updateScheduledOrder);

                        // Check each cargo that was added. If one of the cargos has the same name as in the cargo form,
                        // clear the cargo form.
                        newEntities.forEach(entity => {
                            if (entity.classType === 'flytsuite.cgonode' && entity.name === state.forms.cargoForm.name?.value) {
                                newState = {
                                    ...newState,
                                    forms: {
                                        ...newState.forms,
                                        cargoForm: DEFAULT_STATE.forms.cargoForm
                                    }
                                }
                            }
                        })
                    }

                    // Recalculate transitTypes
                    newEntities = newEntities
                        .map(entity => ({
                            ...entity,
                            transitType: getTransitType(entity.departureID, entity.destinationID)
                        }))

                    newState = {
                        ...newState,
                        ...HelperFuncs.addVisibleLegs(
                            uniqBy(
                                action.add.map(p => (
                                    {
                                        departureID: p.departureID?._id,
                                        destinationID: p.destinationID?._id
                                    }
                                )),
                                HelperFuncs.depDestToString
                            )
                        ),
                        carriableSelector: {
                            ...newState.carriableSelector,
                            selectedPersonnelIds: difference(newState.carriableSelector.selectedPersonnelIds, action.add.map(p => p.personID?._id))
                        },
                        data: {
                            ...newState.data,
                            modifiedEntities: newEntities
                        }
                    }
                }

                return newState;
            }

            // Query status
            case ActionType.SET_QUERY_STATUS:
                return {
                    ...state,
                    queryStatus: action.payload
                }

            // Save status
            case ActionType.SET_SAVE_STATUS:
                return {
                    ...state,
                    saveStatus: action.payload
                }
            case ActionType.DISMISS_SAVE_STATUS:
                return {
                    ...state,
                    saveStatus: undefined
                }

            case ActionType.SET_SELECTED_PERSONNEL_IDS:
                return {
                    ...state,
                    carriableSelector: {
                        ...state.carriableSelector,
                        selectedPersonnelIds: action.payload
                    }
                }
            case ActionType.SET_CARRIABLE_SELECTOR_TAB:
                return {
                    ...state,
                    carriableSelector: {
                        ...state.carriableSelector,
                        upperTab: action.payload.upperTab,
                        lowerTab: action.payload.lowerTab
                    }
                }
            case ActionType.SET_EDIT_MODE:
                return {
                    ...state,
                    editMode: action.payload,
                    saveStatus: action.payload === true ? undefined : state.saveStatus,
                    editor: {
                        ...state.editor,
                        selectedEntityIds: []
                    }
                }
            case ActionType.SET_CARRIABLE_SEL_SEARCH:
                return {
                    ...state,
                    carriableSelector: {
                        ...state.carriableSelector,
                        searchForm: action.payload
                    }
                }

            // If payload is undefined, then this state will toggle
            case ActionType.SET_HIDE_CARRIABLE_PANEL:
                return {
                    ...state,
                    carriableSelector: {
                        ...state.carriableSelector,
                        hidePanel: action.payload === undefined ? !state.carriableSelector.hidePanel : action.payload
                    }
                }

            // Editor
            case ActionType.SET_SELECTED_ENTITY_IDS:
                if (!action.add && !action.remove && !action.set) return;

                let newSelEntityIds = state.editor.selectedEntityIds;

                if (action.set){
                    newSelEntityIds = action.set;
                }
                if (action.remove){
                    newSelEntityIds = difference(newSelEntityIds, action.remove);
                }
                if (action.add){
                    newSelEntityIds = union(newSelEntityIds, action.add);
                }

                return {
                    ...state,
                    editor: {
                        ...state.editor,
                        selectedEntityIds: newSelEntityIds
                    }
                }

            case ActionType.CLEAR_CHARGE_CODES:
                const clearEntityChargeCode = (entity: ScheduleNode) => {
                    if (entity.classType === 'flytsuite.paxnode'){
                        return {
                            ...entity,
                            chargeCode: null
                        }
                    }
                    return entity
                }

                return {
                    ...state,
                    scheduledEntities: state.data.modifiedEntities.map(clearEntityChargeCode)
                }
            case ActionType.SET_DEFAULT_CHARGE_CODE:
                return {
                    ...state,
                    editor: {
                        ...state.editor,
                        defaultChargeCode: action.payload
                    }
                }
            case ActionType.SET_LEG_SELECTOR:
                return {
                    ...state,
                    carriableSelector: {
                        ...state.carriableSelector,
                        legSelector: {
                            ...state.carriableSelector.legSelector,
                            departure: action.payload.departure,
                            destination: action.payload.destination
                        }
                    }
                }
            case ActionType.SET_ISN_DRAWER:
                return {
                    ...state,
                    editor: {
                        ...state.editor,
                        isnDrawer: {
                            pax: action.payload.pax,
                            visible: action.payload.visible
                        }
                    }
                }
            case ActionType.SET_VISIBLE_LEGS:

                let visibleLegs: typeof DEFAULT_STATE.editor.visibleLegs = [];

                if (action.mode === 'objects'){
                    visibleLegs = action.payload;
                }
                else if (action.mode === 'strings')
                {
                    for (let locIDCombined of action.payload) {
                        const separated = HelperFuncs.depDestFromString(locIDCombined, action.splitBy);
                        if (separated){
                            visibleLegs.push({
                                departureID: separated.departureID,
                                destinationID: separated.destinationID
                            })
                        }
                    }
                }

                if (action.action === 'set'){
                    return HelperFuncs.setVisibleLegs(visibleLegs);
                }
                else
                {
                    return HelperFuncs.addVisibleLegs(visibleLegs);
                }

            // Modals
            case ActionType.SET_PAXNODE_MODAL:

                let paxModal = {
                    visible: action.visible !== undefined ? action.visible : state.modal.paxModal.visible,
                    pax: action.pax !== undefined ? action.pax : state.modal.paxModal.pax
                }

                if (!paxModal.pax && paxModal.visible){
                    // Can't be visible without pax data
                    paxModal.visible = false;
                }

                return {
                    ...state,
                    modal: {
                        ...state.modal,
                        paxModal: paxModal
                    }
                }
            case ActionType.SET_NEW_LEG_MODAL:

                let newLegForm = state.forms.newLegForm;
                if (action.newLegValue){
                    newLegForm = {
                        ...newLegForm,
                        depDest: createFormField({
                            departure: action.newLegValue.departure,
                            destination: action.newLegValue.destination
                        })
                    }
                }

                return {
                    ...state,
                    modal: {
                        ...state.modal,
                        newLegFormModal: {
                            ...state.modal.newLegFormModal,
                            // Callback must be cleared if the modal is made non-visible
                            callback: action.visible ? action.callback : null,
                            visible: action.visible
                        }
                    },
                    forms: {
                        ...state.forms,
                        newLegForm: newLegForm
                    }
                }
            case ActionType.NOTES_FOR_PILOT_POPUP: {

                let entityId = action.entityId;

                if (action.action.type === 'EDIT_MODE' && !action.action.value) {
                    // User is leaving edit mode. entityId can be cleared.
                    entityId = null;
                }

                if (
                    state.notesForPilotPopup.entityId !== action.entityId &&
                    state.notesForPilotPopup.editMode
                ){
                    // Cannot cancel editing of another while in edit mode.
                    return state;
                }

                let newState = cloneDeep(state.notesForPilotPopup);

                newState = {
                    ...newState,
                    ...NotesForPilot.QuickEdit.Reducer(state.notesForPilotPopup, action.action)
                }

                // If the entityId is changed, reset the text states.
                if (action.entityId !== state.notesForPilotPopup.entityId){

                    const origText = (state.data.modifiedEntities
                        .find(entity => getSNodeID(entity) === entityId)?.notesForPilot) || '';

                    newState = {
                        ...newState,
                        originalText: origText,
                        modifiedText: origText
                    }
                }

                return {
                    ...state,
                    notesForPilotPopup: {
                        ...newState,
                        entityId: entityId
                    }
                }
            }
            case ActionType.NOTES_FOR_PILOT_EDITOR:
            {
                return produce(state, (newState) => {
                    if (action.action){
                        newState.notesForPilotEditor.state = NotesForPilot.Editor.Reducer(state.notesForPilotEditor.state, action.action);
                    }

                    // if (action.action.type === 'INIT' && state.notesForPilotEditor.open){
                    //     // Prevent spurious inits from triggering while open
                    //     newState.notesForPilotEditor.state = {...state.notesForPilotEditor.state};
                    // }

                    if (action.open !== undefined){
                        newState.notesForPilotEditor.open = Boolean(action.open);
                    }

                    if (action.open === false){
                        // Reset data when closed
                        newState.notesForPilotEditor.state = NotesForPilot.Editor
                            .Reducer(newState.notesForPilotEditor.state, { type: 'RESET' });
                    }
                })
            }
            case ActionType.SUBMIT_NOTES_FOR_PILOT_EDITOR:
            {
                return produce(state, (newState) => {

                    newState.data.modifiedEntities = newState.data.modifiedEntities
                        .map(entity => (
                            {
                                ...entity,
                                notesForPilot: (
                                    NotesForPilot.Editor.Util
                                        .getEntityNoteMsg(entity, newState.notesForPilotEditor.state)
                                )
                            }
                        ))

                    newState.notesForPilotEditor.state = NotesForPilot
                        .Editor
                        .Reducer(newState.notesForPilotEditor.state, {
                            type: 'INIT',
                            entities: newState.data.modifiedEntities
                        });

                    newState.notesForPilotEditor.open = false;
                })
            }
        }
    }
}

export interface ContextType {
    api: Phase2Api
}

export const Context = React.createContext<ContextType>(null);
export const Provider = Context.Provider;