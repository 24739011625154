import React from 'react';
import { Row, Col, Card, Button } from 'antd';
import LinkWrapper from '../../LinkWrapper';

const cardLinkStyle = {
    position: 'absolute',
    bottom: 24,
    right: 24
}

class WorkFlowCard extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            showDetailedDesc: false
        }
    }
    render(){
        const { number, title, description, detaileddescription, path, disabled, ...rest } = this.props;
        const { showDetailedDesc } = this.state;
        var cardStyle = {}
        if (disabled){
            cardStyle = {
                opacity: 0.5
            }
        }
        const renderCard = () => (
            <Card 
                style={{minHeight: '13rem', ...cardStyle}} 
                hoverable={!disabled} {...rest} 
                onMouseEnter={() => this.setState({ showDetailedDesc: true })}
                onMouseLeave={() => this.setState({ showDetailedDesc: false })}
                >
                <Row type="flex" style={{marginBottom: 48}}>
                    <Col style={{margin: '0 1rem'}}>
                        <div style={{fontSize: '4rem'}}>{number}</div>
                    </Col>
                    <Col style={{flex: 1, marginLeft: '1rem'}}>
                        <h2>{title}</h2>
                        <p>{description}</p>
                        {showDetailedDesc && !disabled && detaileddescription && detaileddescription}
                    </Col>
                </Row>
                <div style={cardLinkStyle}>
                    {!disabled && <Button className="mc-link-btn">Click here to begin</Button>}
                    {disabled && <span>You are not authorized to perform this function</span>}
                </div>
            </Card>
        )
        if (!disabled){
            return (
                <LinkWrapper to={path}>
                    {renderCard()}
                </LinkWrapper>
            )
        }
        return renderCard()
    }
}

// const WorkFlowCard = ({ number, title, description, path, disabled, ...rest }) => {
//     var cardStyle = {}
//     if (disabled){
//         cardStyle = {
//             opacity: 0.5
//         }
//     }
//     const renderCard = () => (
//         <Card style={{minHeight: '13rem', ...cardStyle}} hoverable={!disabled} {...rest} onMouseEnter>
//             <Row style={{marginBottom: 24}}>
//                 <Col span={4}>
//                     <div style={{fontSize: '4rem'}}>{number}</div>
//                 </Col>
//                 <Col span={20}>
//                     <h2>{title}</h2>
//                     <p>{description}</p>
//                 </Col>
//             </Row>
//             <div style={cardLinkStyle}>
//                 {!disabled && <a>Click here to begin</a>}
//                 {disabled && <span>You are not authorized to perform this function</span>}
//             </div>
//         </Card>
//     )
//     if (!disabled){
//         return (
//             <LinkWrapper to={path}>
//                 {renderCard()}
//             </LinkWrapper>
//         )
//     }
//     return renderCard()
// }

export default WorkFlowCard